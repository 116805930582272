import superFetch from "../../helpers/superFetch"


export const getCustomerGradeService = async (IDCNO) => {
    try {
        return await superFetch.get(`/api/customer-grade/${IDCNO}`);
    } catch (error) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const getGradeConditionService = async () => {
    try {
        return await superFetch.get(`/api/customer-grade/grade-condition/view`);
    } catch (error) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}