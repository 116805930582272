import superFetch from "../../helpers/superFetch";
import { serialize } from "../../utils/serialize";

export const connectUserService = async (userId, IDCNO) => {
  try {
    return await superFetch.post("/api/users-company", {
      userId,
      IDCNO
    });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const getUsersService = async props => {
  try {
    return await superFetch.get(
      `/api/users-company?${serialize(props)}`
    );
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const detachUsersService = async id => {
  try {
    return await superFetch.delete(
      `/api/users-company/${id}`
    );
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const getUsersAllCompanyService = async props => {
  try {
    return await superFetch.get(
      `/api/users-company/search?${serialize(props)}`
    );
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};
