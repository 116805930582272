const companyAction = {
    CREATE_COMPANY: 'CREATE_COMPANY',
    CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_ERROR: 'CREATE_COMPANY_ERROR',

    UPDATE_COMPANY: 'UPDATE_COMPANY',
    UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR',

    GET_COMPANY: 'GET_COMPANY',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

    DELETE_COMPANY: 'DELETE_COMPANY',
    DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
    DELETE_COMPANY_ERROR: 'DELETE_COMPANY_ERROR',

    GET_COMPANY_OPTIONS: 'GET-COMPANY-OPTIONS',
    GET_COMPANY_OPTIONS_SUCCESS: 'GET-COMPANY-OPTIONS-SUCCESS',
    GET_COMPANY_OPTIONS_ERROR: 'GET-COMPANY-OPTION-ERROR',

    get: () => ({
        type: companyAction.GET_COMPANY
    }),

    getCompanyOptions: () => {
        return { type: companyAction.GET_COMPANY_OPTIONS }
    },

    create: (props) => ({
        type: companyAction.CREATE_COMPANY,
        ...props
    }),
    update: (props) => ({
        type: companyAction.UPDATE_COMPANY,
        ...props
    }),

    delete: (props) => ({
        type: companyAction.DELETE_COMPANY,
        ...props
    })
}

export default companyAction 