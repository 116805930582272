import superFetch from "../../helpers/superFetch"

export const getGradeItemService = async (gradeName) => {
    try {
        return await superFetch.get(`/api/grade?gradeName=${gradeName}`)
    } catch (error) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const getGradeConditionService = async (gradeId) => {
    try {
        return await superFetch.get(`/api/grade/${gradeId}/condition`);
    } catch (error) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const updateGradeItemService = async (payload) => {
    try {
        return await superFetch.put(`/api/grade`, payload);
    } catch (error) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}