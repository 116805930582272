import React from "react";
import "antd/dist/antd.css";
import "./style.css";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu, Icon, Tooltip } from "antd";
import { OverrideStyle } from "./override.style";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};


class SiderBar extends React.Component {
  replaceSlash = url => url.substring(0, url.length - 1);

  render() {
    const { collapsed, location, isMobile, sideBar = [] } = this.props;
    const url = stripTrailingSlash(this.props.url);
    const isSlash =
      location.pathname.charAt(location.pathname.length - 1) === "/";
    const mobileStyle = isMobile
      ? {}
      : collapsed
        ? {}
        : {
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0
        };
    return (
      <div
        style={{
          display: "flex",
          background: "#da2d29",
          minHeight: "100vh",
        }}
      >
        <OverrideStyle>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="xxl"
            style={{
              background: "#da2d29",
              ...mobileStyle,
            }}
          >
            <div>
              <h2 className="textHeader">
                {collapsed ? <Icon type="appstore" /> : this.props.sessionName}
              </h2>
            </div>
            <Menu
              style={{ background: "#da2d29" }}
              theme="dark"
              mode="inline"
              selectedKeys={
                isSlash
                  ? [this.replaceSlash(location.pathname)]
                  : [location.pathname]
              }
            >
              {sideBar ? (
                sideBar.map((item, i) => {
                  const { code, name, icon, subMenu } = item;
                  if (subMenu.length) {
                    return (
                      <SubMenu
                        key={`sub/${i}`}
                        title={
                          <Tooltip placement="rightTop" title={name}>
                            <span>
                              <Icon type={icon} />
                              <span>{name}</span>
                            </span>
                          </Tooltip>
                        }
                      >
                        {subMenu.map((subItem) => {
                          return (
                            <Menu.Item key={`${url}/${subItem.code}`}>
                              <Tooltip
                                placement="rightTop"
                                title={subItem.name}
                              >
                                <Link to={`${url}/${subItem.code}`}>
                                  <span
                                    style={{
                                      display: "block",
                                      width: 120,
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {subItem.name}
                                  </span>
                                </Link>
                              </Tooltip>
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={`${url}/${code}`}>
                      {icon ? <Icon type={icon} /> : ""}
                      <span>{name}</span>
                      <Link to={`${url}/${code}`} />
                    </Menu.Item>
                  );
                })
              ) : (
                  <div></div>
                )}
            </Menu>
            <Menu
              style={{ background: "#da2d29" }}
              theme="dark"
              mode="inline">
              <Menu.Item>
                <a href="https://credit.yasothonpico.com/" target="_blank">ตรวจ PP</a>
              </Menu.Item>
            </Menu>
          </Sider>
        </OverrideStyle>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {

  return { sideBar: Auth.sideBar, sessionName: Auth.sessionName };
}
export default connect(mapStateToProps, null)(withRouter(SiderBar));
