import GradeAction from './actions';

const {
    GET_GRADE_ITEM,
    GET_GRADE_ITEM_SUCCESS,
    GET_GRADE_ITEM_ERROR,
    GET_CONDITION_ITEM,
    GET_CONDITION_ITEM_ERROR,
    GET_CONDITION_ITEM_SUCCESS,
    CHECKED_CONDITION_ITEM,
    UPDATE_GRADE_ITEM,
    UPDATE_GRADE_ITEM_ERROR,
    UPDATE_GRADE_ITEM_SUCCESS,
    CLEAR_DATA
} = GradeAction;

const initState = {
    loading: false,
    gradeItem: undefined,
    gradeCondition: undefined
}

export default function gradeConditionMgntReducer(state = initState, action) {
    switch (action.type) {
        case GET_GRADE_ITEM:
            return { ...state, loading: true }
        case GET_GRADE_ITEM_SUCCESS:
            return { ...state, loading: false, gradeItem: action.data };
        case GET_GRADE_ITEM_ERROR:
            return { ...state, loading: false };
        case GET_CONDITION_ITEM:
            return { ...state, loading: true }
        case GET_CONDITION_ITEM_SUCCESS:
            return { ...state, gradeCondition: action.data, loading: false, }
        case GET_CONDITION_ITEM_ERROR:
            return { ...state, loading: false };
        case CHECKED_CONDITION_ITEM:
            const { conditionId, checked } = action;
            const newGradeCondition = state.gradeCondition.map(elem => {
                if (conditionId === elem.conditionId) {
                    elem.value = checked;
                }

                return { ...elem };
            })
            return { ...state, gradeCondition: newGradeCondition };
        case UPDATE_GRADE_ITEM:
            return { ...state, loading: true };
        case UPDATE_GRADE_ITEM_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_GRADE_ITEM_ERROR:
            return { ...state, loading: false };
        case CLEAR_DATA: 
            return { ...initState };
        default:
            return state;
    }
}