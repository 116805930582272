import {
  all,
  takeEvery,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import {
  delay
} from 'redux-saga';
//import {push} from 'react-router-redux';
import actions from './actions';
import { policyActiveService, policyInactiveService } from './services';

export function* getpolicyActive() {
  while (true) {
  try {
    const { page, IDCNO, policyNo } = yield take(actions.STATEMENT_POLICYACTIVE)
    //yield delay(1500)
    const { response, timeout } = yield race({ response: call(policyActiveService, page, IDCNO, policyNo), timeout: call(delay, 10000) })
    const { success, policy, numOfPolicyActive, message } = response;
    if (success && !timeout) {
      yield put({ type: actions.STATEMENT_POLICYACTIVE_SUCCESS, payload: policy, numOfPolicyActive });
    } else {
      yield put({ type: actions.STATEMENT_ERROR, success, message });
    }
  } catch (err) {
    yield put({ type: actions.STATEMENT_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
  }
}
}
export function* getpolicyInactive() {
  while (true) {
  try {
    const { page, IDCNO, policyNo } = yield take(actions.STATEMENT_POLICYINACTIVE)
    //yield delay(1500)
    const { response, timeout } = yield race({ response: call(policyInactiveService, page, IDCNO, policyNo), timeout: call(delay, 10000) })
    const { success, policy, numOfPolicyInActive, message } = response;
    if (success && !timeout) {
      yield put({ type: actions.STATEMENT_POLICYINACTIVE_SUCCESS, payload: policy, numOfPolicyInActive });
    } else {
      yield put({ type: actions.STATEMENT_ERROR, success, message });
    }
  } catch (err) {
    yield put({ type: actions.STATEMENT_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
  }
}
}
export function* getStatementsError() {
  yield takeEvery(actions.STATEMENT_ERROR, function* () {});
}

export default function* rootSaga() {
  yield all([
    fork(getpolicyActive), 
    fork(getpolicyInactive), 
    fork(getStatementsError)]);
}
