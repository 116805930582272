import superFetch from "../../helpers/superFetch"

export default {
    getAllConditionRangeService: async (gradeName) => {
        try {
            return await superFetch.get(`/api/grade-condition`)
        } catch (error) {
            return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
        }
    },
    getAllComparisonOperatorService: async () => {
        try {
            return await superFetch.get(`/api/comparison-operator`);
        } catch (error) {
            return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
        }
    },
    editComparisonOperatorService: async (props) => {
        try {
            return await superFetch.put(`/api/grade-condition`, props);
        } catch (error) {
            return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
        }
    }
}