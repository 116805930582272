import superFetch from "../../helpers/superFetch"
import { serialize } from "../../utils/serialize";

export const notifacationService = (userID, notifyType, title, description) => {
  return superFetch.post(`/api/notifications`, { userID, notifyType, title, desc: description })
}

export const settingNotifyService = (msgID, title, desc) => {
  return superFetch.put(`/api/notifications/setup`, { msgID, title, desc });
}

export const userService = () => {
  return superFetch.get(`/api/auth/users`);
}

export const getSettingsService = () => {
  return superFetch.get(`/api/notifications/setup`);
}

export const getUserNotifyService = (page, userID) => {
  return superFetch.get(`/api/notifications?${serialize({ ...page, userID })}`, { page, userID });
}