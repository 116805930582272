import { all, takeEvery, put, fork, call, take, race } from "redux-saga/effects";
import { delay } from "redux-saga";
import { message } from "antd";

import actions from "./actions";
import {
  connectUserService,
  getUsersService,
  detachUsersService,
  getUsersAllCompanyService
} from "./services";

export function* connectUserWorker() {
  while (true) {
    try {
      const { userId, IDCNO = '', onSuccess } = yield take(actions.CONNECT_USER_TO_COMPANY); //ดัก action ALLUSER
      //yield delay(1500)
      const { res, timeout } = yield race({
        res: call(connectUserService, userId, IDCNO),
        timeout: call(delay, 10000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess()
        yield put({
          type: actions.CONNECT_USER_TO_COMPANY_SUCCESS
        });
      } else {
        message.error(res.message);
        yield put({
          type: actions.CONNECT_USER_TO_COMPANY_ERROR
        });
      }
    } catch (err) {
      message.error("ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้");
      yield put({
        type: actions.CONNECT_USER_TO_COMPANY_ERROR
      });
    }
  }
}


export function* getUsersWorker() {
  yield takeEvery(actions.GET_USER, function* ({ props }) {
    try {
      const { res, timeout } = yield race({
        res: call(getUsersService, props),
        timeout: call(delay, 15000)
      });
      const { success, data } = res;
      if (success && !timeout) {
        yield put({
          type: actions.GET_USER_SUCCESS,
          data, numOfRows: res.numOfRows
        });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: actions.GET_USER_ERROR });
    }
  });
}


export function* detachUsersWorker() {
  yield takeEvery(actions.DETACH_USER_FROM_COMPANY, function* ({ id }) {
    try {
      const { res, timeout } = yield race({
        res: call(detachUsersService, id),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        yield put({ type: actions.DETACH_USER_FROM_COMPANY_SUCCESS })
        yield put({ type: actions.GET_USER })
      } else {
        throw new Error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: actions.DETACH_USER_FROM_COMPANY_ERROR });
    }
  });
}

export function* searchUserAllCompany() {
  yield takeEvery(actions.SEARCH_USER_ALL_COMPANY, function* (props) {
    try {
      const { keyword } = props;
      const { res, timeout } = yield race({
        res: call(getUsersAllCompanyService, { keyword }),
        timeout: call(delay, 15000)
      });

      if (res.success && !timeout) {
        yield put({ type: actions.SEARCH_USER_ALL_COMPANY_SUCCESS, data: res.data });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      message.error('ไม่สามารถทำรายการได้');
      yield put({ type: actions.SEARCH_USER_ALL_COMPANY_ERROR });
    }
  })
}


export default function* rootSaga() {
  yield all([
    fork(detachUsersWorker),
    fork(connectUserWorker),
    fork(getUsersWorker),
    fork(searchUserAllCompany)
  ]);
}
