import styled from 'styled-components'

export const OverrideStyle = styled.div`
.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #e83a35;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub { 
    background: #e83a36;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0) inset;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0) inset;
}`