const actions = {
    GET_DB: 'GET-DATABASE',
    GET_DB_SUCCESS: 'GET-DB-SUCCESS',
    GET_DB_ERROR: 'GET-DB-ERROR',

    getAll: function () {
        return { type: actions.GET_DB }
    },
    
    CHANGE_MODE_REQ: 'CHANGE-MODE-REQ',
    CHANGE_MODE_SUCCESS: 'CHANGE-MODE-SUCCESS',
    CHANGE_MODE_ERROR: 'CHANGE-MODE-ERROR',
    UPDATE_MODE:'UPDATE-MODE-NAME',

    changeMode: (mode, onSuccess) => {
        return { type: actions.CHANGE_MODE_REQ, mode, onSuccess };
    }
}

export default actions;