const newsActons = {
  NEWS_GETLISTDATA: 'NEWS_GETLISTDATA',
  NEWS_GETLISTDATA_SUCCESS: 'NEWS_GETLISTDATA_SUCCESS',
  NEWS_DELETEDATA: 'NEWS_DELETEDATA',
  NEWS_DELETEDATA_SUCCESS: 'NEWS_DELETEDATA_SUCCESS',
  NEWS_ADDNEWSPOMOSION: 'NEWS_ADDNEWSPOMOSION',
  NEWS_ADDNEWSPOMOSION_SUCCESS: 'NEWS_ADDNEWSPOMOSION_SUCCESS',
  NEWS_ERROR: 'NEWS_ERROR',
  RESET_STATE: 'RESET_STATE',

  getNews: () => ({
    type: newsActons.NEWS_GETLISTDATA
  }),
  deleteNews: (newsID) => ({
    type: newsActons.NEWS_DELETEDATA,
    newsID
  }),
  addNews: (props, redirect ) => ({
    type: newsActons.NEWS_ADDNEWSPOMOSION, 
    ...props,
    redirect
  }),
  ResetState: () => ({
    type: newsActons.RESET_STATE,
  }),
};
export default newsActons;
