const userActons = {
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_ERROR: "ADD_USER_ERROR",

  EDIT_USER: "EDIT_USER",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "EDIT_USER_ERROR",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",

  USER_LISTALLUSER: "USER_LISTALLUSER",
  USER_LISTALLUSER_SUCCESS: "USER_LISTALLUSER_SUCCESS",
  USER_LISTALLUSERADMIN: "USER_LISTALLUSERADMIN",
  USER_LISTALLUSERADMIN_SUCCESS: "USER_LISTALLUSERADMIN_SUCCESS",
  USER_RESETPASSWORD: "USER_RESETPASSWORD",
  USER_RESETPASSWORDEMP: "USER_RESETPASSWORDEMP",
  USER_RESETPASSWORDEMP_SUCCESS: "USER_RESETPASSWORDEMP_SUCCESS",
  USER_RESETIDCNO: "USER_RESETIDCNO",
  USER_RESETIDCNOSUCCESS: "USER_RESETIDCNOSUCCESS",
  USER_RESET_STATE: "USER_RESET_STATE",
  USER_RESETSUCCESS: "USER_RESETSUCCESS",
  USER_ADDEMPLOYEE: "USER_ADDEMPLOYEE",
  USER_ADDEMPLOYEE_SUCCESS: "USER_ADDEMPLOYEE_SUCCESS",
  USER_ERROR: "USER_ERROR",
  EMP_LISTADMINUSER: "EMP_LISTADMINUSER",
  EMP_LISTADMINUSER_SUCCESS: "EMP_LISTADMINUSER_SUCCESS",
  EDIT_USERS_ADMIN: "EDIT_USERS_ADMIN",
  EDIT_USERS_ADMIN_SUCCESS: "EDIT_USERS_ADMIN_SUCCESS",
  DELETE_ADMIN_USER: "DELETE_ADMIN_USER",
  DELETE_ADMIN_USER_SUCCESS: "DELETE_ADMIN_USER_SUCCESS",

  addUser: props => ({
    type: userActons.ADD_USER,
    props
  }),

  editUser: props => ({
    type: userActons.EDIT_USER,
    props
  }),

  deleteUser: props => ({
    type: userActons.DELETE_USER,
    props
  }),

  editAdminUsers: (props, redirect) => ({
    type: userActons.EDIT_USERS_ADMIN,
    props,
    redirect
  }),
  deleteAdminUsers: empID => ({
    type: userActons.DELETE_ADMIN_USER,
    empID
  }),
  getUsers: props => ({
    type: userActons.USER_LISTALLUSER,
    props
  }),
  getemployees: props => ({
    type: userActons.USER_LISTALLUSERADMIN,
    props
  }),
  addEmployees: (empRegisterDatas, redirect) => ({
    type: userActons.USER_ADDEMPLOYEE,
    empRegisterDatas,
    redirect
  }),
  resetPWD: (userID, password, confirmPassword) => ({
    type: userActons.USER_RESETPASSWORD,
    userID,
    password,
    confirmPassword
  }),
  resetpwdEmp: (empID, newPassword, confirmPassword) => ({
    type: userActons.USER_RESETPASSWORDEMP,
    empID,
    newPassword,
    confirmPassword
  }),
  resetIDCNO: (userID, IDCNO) => ({
    type: userActons.USER_RESETIDCNO,
    userID,
    IDCNO
  }),
  resetSuccess: () => ({
    type: userActons.USER_RESETSUCCESS
  }),
  reSetState: () => ({
    type: userActons.USER_RESET_STATE
  })
};
export default userActons;