import actions from './actions';

    const initState = {
      acRequestList: [],
      inRequestList: [],
      Loading: false,
      success: false,
      message: '',
      numAcRequest: 0,
      numInRequest: 0,
      }; //init state

export default function requestReducer(state = initState, action) {
    switch (action.type) {
      case actions.AC_REQUEST_DATA:
        return { 
          ...state,
          Loading: true,
          message: null
        };
      case actions.AC_REQUEST_DATA_SUCCESS:
        return {
          ...state,
          Loading: false,
          acRequestList: action.data,
          numAcRequest: action.numOfReq
        };
      case actions.IN_REQUEST_DATA:
        return {
          ...state,
          Loading: true,
          message: null
        };
      case actions.IN_REQUEST_DATA_SUCCESS:
        return {
          ...state,
          Loading: false,
          inRequestList: action.data,
          numInRequest: action.numOfReq
        };

      case actions.CHANGE_STATUS_REQUEST:
        return {
          ...state,
          Loading: true,
        };
      case actions.CHANGE_STATUS_REQUEST_SUCCESS:
        return {
          ...state,
          Loading: false,
          success: action.success
        };
      case actions.RESET_STATE:
        return {
          ...state,
          ...initState
        };
      case actions.REQUEST_ERROR:
      return {
        ...state,
        ...initState,
        success: action.success,
        message: action.message
      };
    default:
      return state;
  }
}
