import actions from './actions';

const initState = {
    loading: false,
    companyList: [],
    accountType: [],
    pad: null,
    success: true,
    message: '',
};

export default (state = initState, action) => {
    switch (action.type) {
        case actions.GET_OPTION:
            return { ...state, loading: true };
        case actions.GET_OPTION_SUCCESS:
            return { ...state, companyList: action.companyList, accountType: action.accountType, success: true }
        case actions.GET_OPTION_ERROR:
            return { ...initState, message: action.message, success: false };
            //accountType

        case actions.Get_Accrued_Interest_Report:
            return { ...state, loading: true };
        case actions.Get_Accrued_Interest_Report_Success:
            return { ...state, pad: action.paf, success: true }
        case actions.Get_Accrued_Interest_Report_Error:
            return { ...state, message: action.message, success: false };

        default:
            return state;
    }
}