import actions from "./actions";

const {
  GET_LOGIN_LOCATION,
  GET_LOGIN_LOCATION_SUCCESS,
  GET_LOGIN_LOCATION_ERROR
} = actions;

const initState = {
  getLoading: false,
  loginLocationLists: [],
  numOfData: 0
};

export default function loginLocationReducer(state = initState, action) {
  switch (action.type) {
    case GET_LOGIN_LOCATION:
      return {
        ...state,
        getLoading: true
      };
    case GET_LOGIN_LOCATION_SUCCESS:
      return {
        ...state,
        getLoading: false,
        loginLocationLists: action.data,
        numOfData: action.numOfData
      };
    case GET_LOGIN_LOCATION_ERROR:
      return {
        ...state,
        getLoading: false
      };

    default:
      return state;
  }
}
