import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import { createService, getService, updateService, deleteService, getCompanyOptionsService } from './services'

const { 
    GET_COMPANY, 
    GET_COMPANY_SUCCESS, 
    GET_COMPANY_ERROR, 
    CREATE_COMPANY, 
    CREATE_COMPANY_SUCCESS, 
    CREATE_COMPANY_ERROR, 
    UPDATE_COMPANY, 
    UPDATE_COMPANY_SUCCESS, 
    UPDATE_COMPANY_ERROR, 
    DELETE_COMPANY, 
    DELETE_COMPANY_SUCCESS, 
    DELETE_COMPANY_ERROR } = actions

export function* getCompanyOptions() {
    yield takeEvery(actions.GET_COMPANY_OPTIONS, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(getCompanyOptionsService),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                yield put({ type: actions.GET_COMPANY_OPTIONS_SUCCESS, data: res.data });
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: actions.GET_COMPANY_OPTIONS_ERROR });
        }
    })
}

export function* getCompanyWorker() {
    yield takeEvery(GET_COMPANY, function* ({ }) {
        try {
            const { res, timeout } = yield race({
                res: call(getService),
                timeout: call(delay, 15000)
            })
            const { success, data } = res
            if (success && !timeout) {
                yield put({ type: GET_COMPANY_SUCCESS, data })
            } else {

            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GET_COMPANY_ERROR});
        }
    })
}

export function* createCompanyWorker() {
    yield takeEvery(CREATE_COMPANY, function* ({ preparedata, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(createService, preparedata),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                yield put({ type: CREATE_COMPANY_SUCCESS })
                message.success(res.message)
                onSuccess()
                yield put({ type: actions.GET })
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: CREATE_COMPANY_ERROR });
        }
    })
}

export function* updateCompanyWorker() {
    yield takeEvery(UPDATE_COMPANY, function* ({ companyId, preparedata, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(updateService, companyId, preparedata),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                yield put({ type: UPDATE_COMPANY_SUCCESS })
                message.success(res.message)
                onSuccess()
                yield put({ type: actions.GET })
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: UPDATE_COMPANY_ERROR });
        }
    })
}


export function* deleteCompanyWorker() {
    yield takeEvery(DELETE_COMPANY, function* ({ companyId, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(deleteService, companyId),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                yield put({ type: DELETE_COMPANY_SUCCESS })
                message.success(res.message)
                onSuccess();
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: DELETE_COMPANY_ERROR });
        }
    })
}


export default function* rootSagas() {
    yield all([
        fork(createCompanyWorker),
        fork(getCompanyWorker),
        fork(updateCompanyWorker),
        fork(deleteCompanyWorker),
        fork(getCompanyOptions)
    ])
}