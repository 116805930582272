import {
  all,
  takeEvery,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import actions from './actions';
import { dashboardService } from './services';

export function* getDashboard() {
  while (true) {
    try {
      yield take(actions.DASHBOARD_LISTDATA_DASHBOARD)
      //yield delay(1500)
      const { responsDashboard, timeout } = yield race({
        responsDashboard: call(dashboardService),
        timeout: call(delay, 15000)
      });
      const { success, message, numPolicy, numAmount, numDuePayment, lateAmountPayment, numRequest } = responsDashboard;
      if (success && !timeout) {
        yield put({ type: actions.DASHBOARD_LISTDATA_DASHBOARD_SUCCESS, numPolicy, numAmount, numDuePayment, lateAmountPayment, numRequest });
      } else {
        yield put({ type: actions.DASHBOARD_ERROR, success, message });
      }
    } catch (err) {
      yield put({ type: actions.DASHBOARD_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}

export function* getListerror() {
  yield takeEvery(actions.DASHBOARD_ERROR, function* () {});
}

export default function* rootSaga() {
  yield all([fork(getDashboard), fork(getListerror)]);
}
