import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/th'

import './index.css';
import DashApp from './dashApp';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
        <BrowserRouter>
            <DashApp />
        </BrowserRouter>
    ,
    document.getElementById('root')
);

registerServiceWorker();