import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import { checkContractAge } from './services'

const {
    CHECK_CONTRACT_AGE,
    CHECK_CONTRACT_AGE_SUCCESS,
    CHECK_CONTRACT_AGE_ERROR,
 } = actions

export function* checkContractAgeWorker() {
    yield takeEvery(CHECK_CONTRACT_AGE, function* ({ props }) {
        try {
            const { res, timeout } = yield race({
                res: call(checkContractAge, props),
                timeout: call(delay, 15000)
            })
            const { success, data, numOfData } = res
            if (success && !timeout) {
                yield put({ type: CHECK_CONTRACT_AGE_SUCCESS, data, numOfData })
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: CHECK_CONTRACT_AGE_ERROR })
        }
    })
}

export default function* rootSagas() {
    yield all([
        fork(checkContractAgeWorker),
    ])
}