
import actions from './actions';

const {
    CREATE_COMPANY,
    GET_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    CREATE_COMPANY_ERROR,
    CREATE_COMPANY_SUCCESS,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_ERROR,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_ERROR,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_ERROR
} = actions

const initState = {
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    getLoading: false,
    companyLists: []
};

export default function companyReducer(state = initState, action) {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                getLoading: true
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                getLoading: false,
                companyLists: action.data
            };
        case GET_COMPANY_ERROR:
            return {
                ...state,
                getLoading: false
            };
        case actions.GET_COMPANY_OPTIONS_SUCCESS:
            return {
                ...state,
                getLoading: false,
                companyLists: action.data
            };
        case actions.GET_COMPANY_OPTIONS_ERROR:
            return {
                ...state,
                getLoading: false
            };
        case CREATE_COMPANY:
            return {
                ...state,
                createLoading: true
            };
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                createLoading: false
            };
        case CREATE_COMPANY_ERROR:
            return {
                ...state,
                createLoading: false
            };

        case UPDATE_COMPANY:
            return {
                ...state,
                updateLoading: true
            };
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                updateLoading: false
            };
        case UPDATE_COMPANY_ERROR:
            return {
                ...state,
                updateLoading: false
            };


        case DELETE_COMPANY:
            return {
                ...state,
                deleteLoading: true
            };
        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                deleteLoading: false
            };
        case DELETE_COMPANY_ERROR:
            return {
                ...state,
                deleteLoading: false
            };

        default:
            return state;
    }
}
