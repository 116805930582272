const blacklistActions = {
  CREATE_BLACKLIST: "CREATE_BLACKLIST",
  CREATE_BLACKLIST_SUCCESS: "CREATE_BLACKLIST_SUCCESS",
  CREATE_BLACKLIST_ERROR: "CREATE_BLACKLIST_ERROR",

  GET_BLACKLIST: "GET_BLACKLIST",
  GET_BLACKLIST_SUCCESS: "GET_BLACKLIST_SUCCESS",
  GET_BLACKLIST_ERROR: "GET_BLACKLIST_ERROR",

  GET_BLACKLIST_OPT: "GET_BLACKLIST_OPT",
  GET_BLACKLIST_OPT_SUCCESS: "GET_BLACKLIST_OPT_SUCCESS",
  GET_BLACKLIST_OPT_ERROR: "GET_BLACKLIST_OPT_ERROR",

  UNDOREDO_BLACKLIST: "UNDOREDO_BLACKLIST",
  UNDOREDO_BLACKLIST_SUCCESS: "UNDOREDO_BLACKLIST_SUCCESS",
  UNDOREDO_BLACKLIST_ERROR: "UNDOREDO_BLACKLIST_ERROR",

  UPDATE_BL_NOTE: "UPDATE_BL_NOTE",
  UPDATE_BL_SUCCESS: "UPDATE_BL_SUCCESS",
  UPDATE_BL_ERROR: "UPDATE_BL_ERROR",

  GET_BLACKLIST_LOGS: "GET_BLACKLIST_LOGS",
  GET_BLACKLIST_LOGS_SUCCESS: "GET_BLACKLIST_LOGS_SUCCESS",
  GET_BLACKLIST_LOGS_ERROR: "GET_BLACKLIST_LOGS_ERROR",

  SEARCH_BLACKLIST_REQUEST: 'SEARCH-BLACKLIST-REQUEST',
  SEARCH_BLACKLIST_SUCCESS: 'SEARCH-BLACKLIST-SUCCESS',
  SEARCH_BLACKLIST_ERROR: 'SEARCH-BLACKLIST-ERROR',

  searchBlackList: (keyword) => {
    return { type: blacklistActions.SEARCH_BLACKLIST_REQUEST, keyword };
  },

  getBlackListLogs: (ID, logsOnly) => ({
    type: blacklistActions.GET_BLACKLIST_LOGS,
    ID,
    logsOnly
  }),
  updateNote: props => ({
    type: blacklistActions.UPDATE_BL_NOTE,
    ...props
  }),
  undoRedo: props => ({
    type: blacklistActions.UNDOREDO_BLACKLIST,
    ...props
  }),
  create: props => ({
    type: blacklistActions.CREATE_BLACKLIST,
    ...props
  }),
  get: props => ({
    type: blacklistActions.GET_BLACKLIST,
    props
  }),
  getOpt: () => ({
    type: blacklistActions.GET_BLACKLIST_OPT
  })
};

export default blacklistActions;
