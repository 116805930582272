import superFetch from "../../helpers/superFetch";
import { serialize } from "../../utils/serialize";

export const addUserService = async props => {
  try {
    return await superFetch.post(`/api/users`, props);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const editUserService = async props => {
  try {
    return await superFetch.put(`/api/users`, props);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const userService = (props) => {
  const { current, pageSize } = props.page;
  return superFetch.get(`/api/users?${serialize({ current, pageSize, userID: props.userID })}`);
}

export const resetPWService = (userID, password, confirmPassword) => {
  return superFetch.put(`/api/users/resetpassword`, { userID, password, confirmPassword });
}

export const resetIDCNOService = async (userID, IDCNO) => {
  try {
    return await superFetch.put(`/api/users`, { userID, IDCNO });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}

export const employeesService = (props) => {
  const { page: { current, pageSize }, empID, companyName, jobName, permission } = props;
  return superFetch.get(`/api/employees?${serialize({ current, pageSize, empID, companyName, jobName, permission })}`);
}

export const empPermissionListService = async () => {
  try {
    return await superFetch.get(`/api/userLevel`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const empJobnameListService = async () => {
  try {
    return await superFetch.get(`/api/jobPosition`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const deleteAdminUserService = async empID => {
  try {
    return await superFetch.delete(`/api/employees/`, { empID });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const editUserAdminService = async props => {
  try {
    return await superFetch.put(`/api/employees`, { data: props });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const deleteUserService = async userID => {
  try {
    return await superFetch.delete(`/api/users/${userID}`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const resetpwdEmpService = async (
  empID,
  newPassword,
  confirmPassword
) => {
  try {
    return await superFetch.put(`/api/employees/resetPassword`, {
      empID,
      newPassword,
      confirmPassword
    });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const addEmpService = async empRegisterDatas => {
  try {
    return await superFetch.post(`/api/employees`, { empRegisterDatas });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};
