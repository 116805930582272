import React from 'react'
import { Switch, Route } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc';

class AppRouter extends React.Component {
  render() {
    const { url } = this.props
    return (
      <Switch>
        
        <Route
          exact
          path={`${url}/gbprimepay`}
          component={asyncComponent(() => import("../GBPrimePay"))}
        />
        <Route
          exact
          path={`${url}/loan-verification`}
          component={asyncComponent(() => import("../LoanChecker"))}
        />
        <Route
          exact
          path={`${url}/stat`}
          component={asyncComponent(() => import("../Dashboard"))}
        />
        <Route
          exact
          path={`${url}/database-mgnt`}
          component={asyncComponent(() => import("../database"))}
        />
        <Route
          exact
          path={`${url}/guarantor-form`}
          component={asyncComponent(() => import("../setting/grtForm"))}
        />
        <Route
          exact 
          path={`${url}/user-connect-company`}
          component={asyncComponent(() => import("../UserConnectCompany"))}
        />
        <Route
          exact
          path={`${url}/debtor-form`}
          component={asyncComponent(() => import("../setting/debtorForm"))}
        />
        <Route
          exact
          path={`${url}/bank-account-mgnt`}
          component={asyncComponent(() => import("../setting/bank_account"))}
        />
        <Route
          exact
          path={`${url}/customer-grade`}
          component={asyncComponent(() => import("../customerGrade"))}
        />
        <Route
          exact
          path={`${url}/grade-mgnt`}
          component={asyncComponent(() => import("../GradeConditionMgnt"))}
        />
        <Route
          exact
          path={`${url}/condition-mgnt`}
          component={asyncComponent(() => import("../ConditionRangeMgnt"))}
        />
        <Route
          exact
          path={`${url}/blacklist`}
          component={asyncComponent(() => import("../blacklist"))}
        />
        <Route
          exact
          path={`${url}/location`}
          component={asyncComponent(() => import("../loginLocation"))}
        />
        <Route
          exact
          path={`${url}/system-info-mgnt`}
          component={asyncComponent(() => import("../setting/sysInfo"))}
        />
        <Route
          exact
          path={`${url}/company-mgnt`}
          component={asyncComponent(() => import("../company"))}
        />
        <Route
          exact
          path={`${url}/debtor-notification`}
          component={asyncComponent(() => import("../debtor/notify"))}
        />
        <Route
          exact
          path={`${url}/debtor-notification-2`}
          component={asyncComponent(() => import("../debtor2/notify"))}
        />
        <Route
          exact
          path={`${url}/guarantor-notification`}
          component={asyncComponent(() => import("../guarantor/notify"))}
        />
        <Route
          exact
          path={`${url}/guarantor-checking`}
          component={asyncComponent(() => import("../guarantor/checking"))}
        />
        <Route
          exact
          path={`${url}/term-of-contract-checking`}
          component={asyncComponent(() => import("../contract/checkAge"))}
        />
        <Route
          exact
          path={`${url}/statement`}
          component={asyncComponent(() => import("../Statement"))}
        />
        <Route
          exact
          path={`${url}/complaint`}
          component={asyncComponent(() => import("../request"))}
        />
        <Route
          exact
          path={`${url}/notification`}
          component={asyncComponent(() => import("../notification"))}
        />
        <Route
          exact
          path={`${url}/qr-code`}
          component={asyncComponent(() =>
            import("../generateQr/screen/calculator")
          )}
        />
        <Route
          exact
          path={`${url}/accrued-customer-account`}
          component={asyncComponent(() =>
            import("../report/AccruedInterestReport")
          )}
        />
        <Route
          exact
          path={`${url}/ranking-debtor`}
          component={asyncComponent(() => import("../report/debtorRating"))}
        />
        <Route
          exact
          path={`${url}/setting-user-mgnt`}
          component={asyncComponent(() => import("../manageUsers"))}
        />
        <Route
          exact
          path={`${url}/setting-emp-mgnt`}
          component={asyncComponent(() => import("../manageUsers/manageAdmin"))}
        />
        <Route
          exact
          path={`${url}/setting-emp-news`}
          component={asyncComponent(() => import("../manageUsers/manageNews"))}
        />
        <Route
          exact
          path={`${url}/addnews`}
          component={asyncComponent(() =>
            import("../manageUsers/manageNews/addSlidenews/addNews.js")
          )}
        />
        <Route
          exact
          path={`${url}/manageuseredit`}
          component={asyncComponent(() => import("../manageUsers/editUser/"))}
        />
        <Route
          exact
          path={`${url}/resetpassword`}
          component={asyncComponent(() =>
            import("../manageUsers/manageAdmin/manageAdminForm/resetPwdForm.js")
          )}
        />
        <Route
          exact
          path={`${url}/manageuseradmin`}
          component={asyncComponent(() =>
            import("../manageUsers/manageAdmin/manageAdminForm/addEmpForm.js")
          )}
        />
        <Route
          exact
          path={`${url}/setting-emp-permission`}
          component={asyncComponent(() => import("../manageUsers/Permission"))}
        />
      </Switch>
    );
  }
}

export default AppRouter