export default {
  // apiUrl: process.env.NODE_ENV === 'development' ? 'http://192.168.1.125:8080': 'https://api.yasothonpico.com'
  apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8080': 'https://api.yasothonpico.com'
  // process.env.apiUrl
};
// const siteConfig = {
//   siteName: 'KKKB',
//   siteIcon: 'ion-flash',
//   footerText: 'ที่อยู่ ..',
// };

//themeConfig
// const language = 'thai';

// const youtubeSearchApi = '';
// export {
//   siteConfig,
// };
