import actions from './actions';

const initState = {
  Loading: false,
  success: false,
  message: '',
  NewsList: []
}; //init state

export default function requestReducer(state = initState, action) {
  switch (action.type) {
    case actions.NEWS_GETLISTDATA:
      return {
        ...state,
        Loading: true,
        message: null
      };
    case actions.NEWS_GETLISTDATA_SUCCESS:
      return {
        ...state,
        Loading: false,
        NewsList: action.data
      };
    case actions.NEWS_DELETEDATA:
      return {
        ...state,
        Loading: true,
      }
    
    case actions.RESET_STATE:
      return {
        ...state,
        ...initState
      }

    case actions.NEWS_DELETEDATA_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: action.success,
        message: action.message
      };
    case actions.NEWS_ADDNEWSPOMOSION:
      return {
        ...state,
        Loading: true,
      };
    case actions.NEWS_ADDNEWSPOMOSION_SUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        Loading: false,
      }

    case actions.NEWS_ERROR:
      return {
        ...state,
        ...initState
      };
    default:
      return state;
  }
}
