import { serialize } from "../../utils/serialize";
import superFetch from "../../helpers/superFetch";

export const getBankAccount = async companyId => {
  try {
    return await superFetch.get(`/api/account/${companyId}/company`);
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const updateBankAccount = async bank_account_info => {
  try {
    return await superFetch.put(`/api/account`, bank_account_info);
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const deleteBankAccount = async accountId => {
  try {
    return await superFetch.delete(`/api/account`, { accountId });
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const createBankAccount = async bank_account_info => {
  try {
    return await superFetch.post(`/api/account`, bank_account_info);
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};

export const getNotifyForm = async props => {
  try {
    return await superFetch.get(
      `/api/notification-doc-template?${serialize(props)}`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้", success: false };
  }
};

export const getSysInfo = async () => {
  try {
    return await superFetch.get(`/api/system`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้", success: false };
  }
};

export const updateService = async props => {
  try {
    return await superFetch.put(`/api/system`, { ...props });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้", success: false };
  }
};

export const updateNotifyForm = async props => {
  try {
    return await superFetch.put(`/api/notification-doc-template`, { ...props });
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้", success: false };
  }
};
