import actions from './actions';

const initState = {
  genderStatList: [],
  distStatList: [],
  amountStatList: [],
  ageStatList: [],
  Loading: false,

}; //init state

export default function chartReducer(state = initState, action) {
  switch (action.type) {
    case actions.GETALL_STATS_CHART_REQUEST:
      return {
        ...state,
        Loading: true
      }

    case actions.GETALL_STATS_CHART_REQUEST_SUCCESS:
      return {
        ...state,
        Loading: false,
        genderStatList: action.genderStatList,
        distStatList: action.distStatList,
        amountStatList: action.amountStatList,
        ageStatList: action.ageStatList,
        success: action.success
      };

    case actions.GETALL_STATS_CHART_REQUEST_ERROR:
      return {
        ...state,
        ...initState,
        success: action.success, 
        message: action.message
      }
    default:
      return state;
  }
}
