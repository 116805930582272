import { all, takeEvery, put, fork, call, race } from 'redux-saga/effects';
import { delay } from 'redux-saga'
import { push } from 'react-router-redux';
import { notification } from 'antd'
import { signinEmpService, getMeService, signoutEmpService } from './services'
import actions from './actions';
import adminWizardActions from '../AdminWizard/actions';

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* (payload) {
    try {
      const { username, password } = payload
      const { res, timeout } = yield race({ res: call(signinEmpService, username, password), timeout: call(delay, 10000) })
      const { success, message, sideBar } = res
      if (success && !timeout) {
        const { get_me_response, timeout_response } = yield race({ get_me_response: call(getMeService), timeout_response: call(delay, 10000) });
        if (get_me_response.success && !timeout_response) {          
          const permission = get_me_response.permission || 'Admin';

          yield put({
            type: actions.LOGIN_SUCCESS,
            sideBar,
            name: get_me_response.name || `${get_me_response.firstName} ${get_me_response.lastName}`,
            permission,
            sessionName: res.sessionName
          });

          if (permission === 'Admin') {
            yield put(push('/dashboard/database-mgnt'));
          } else {
            yield put(push('/dashboard/stat'));
          }
        } else {
          const error = new Error(get_me_response.message);
          error.statusCode = '400';
          throw error;
        }
      } else {
        const error = new Error(message);
        error.statusCode = '400';
        throw error;
      }
    } catch (err) {
      if (err && err.statusCode == 400) {
        notification["error"]({
          message: 'ERROR',
          description: err.message
        });
      } else {
        notification["error"]({
          message: 'ERROR',
          description: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้'
        });
      }

      yield put({ type: actions.LOGIN_ERROR })
    }
  });
}

export function* getMe() {
  yield takeEvery(actions.ME_REQUEST, function* () {
    try {
      const { res, timeout } = yield race({ res: call(getMeService), timeout: call(delay, 10000) })
      const { success, message, ...rest } = res
      if (success && !timeout) {
        yield put({
          type: actions.ME_SUCCESS,
          data: rest
        });
      } else {
        yield put({ type: actions.ME_ERROR, message });
      }
    } catch (err) {
      yield put({ type: actions.ME_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' })
    }
  })
}

export function* logout() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      const { res, timeout } = yield race({ res: call(signoutEmpService), timeout: call(delay, 10000) })
      const { success, message } = res
      if (success && !timeout) {
        yield put({
          type: actions.LOGOUT_SUCCESS
        });
      } else {
        notification["error"]({
          message: 'ERROR',
          description: message
        })
        yield put({ type: actions.LOGOUT_ERROR, message });
        yield put(push('/'));
      }
    } catch (error) {
      yield put({ type: actions.LOGOUT_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
      yield put(push('/'));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(logout),
    fork(getMe)
  ]);
}
