import { serialize } from '../../utils/serialize'
import superFetch from '../../helpers/superFetch'

export const checkContractAge = async (props) => {
    delete props.data
    try {
        return await superFetch.get(`/api/contract_age_verification?${serialize(props)}`);
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}
