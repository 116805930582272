const actions = {
    GET_GRADE_ITEM: 'get-grade-item',
    GET_GRADE_ITEM_SUCCESS: 'get-grade-item-success',
    GET_GRADE_ITEM_ERROR: 'get-grade-item-error',

    GET_CONDITION_ITEM: 'get-condition-item',
    GET_CONDITION_ITEM_SUCCESS: 'get-condition-item-success',
    GET_CONDITION_ITEM_ERROR: 'get-condition-item-error',

    CHECKED_CONDITION_ITEM: 'checked_condition_item',

    UPDATE_GRADE_ITEM: 'update-grade-item',
    UPDATE_GRADE_ITEM_SUCCESS: 'update-grade-item-success',
    UPDATE_GRADE_ITEM_ERROR: 'update-grade-item-error',

    CLEAR_DATA: 'clear-data',

    getGradeItem: (gradeName) => {
        return { type: actions.GET_GRADE_ITEM, gradeName };
    },

    getGradeCondition: (gradeId) => {
        return { type: actions.GET_CONDITION_ITEM, gradeId };
    },

    checkedConditionItem: (conditionId, checked) => {
        return { type: actions.CHECKED_CONDITION_ITEM, conditionId, checked };
    },

    updateGradeItem: (gradeId) => {
        return { type: actions.UPDATE_GRADE_ITEM, gradeId };
    },

    clearData: () => {
        return { type: actions.CLEAR_DATA };
    }
};

export default actions;