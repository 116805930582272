import actions from './actions'

const initState = {
  pushSuccess: false,
  pushMessage: '',
  success: false,
  message: '',
  loading: false,
  userNotifyList: [],
  loadUserNotify: false,
  numOfNotify: null,
  notifySettingList: []
}; //init state

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_NOTIFY_LIST_REQUEST:
      return {
        ...state,
        loadUserNotify: true
      }
    case actions.GET_NOTIFY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loadUserNotify: false,
        userNotifyList: action.data || [],
        numOfNotify: action.numOfNotify
      }
    
    case actions.FILTER_NAME:
    return {
        ...state,
        userNotifyList: action.newDataFilter || []
    }

    case actions.SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.GET_ALL_SETTING_REQUEST:
      return {
        ...state,
        message: '',
        success: false,
        loading: true
      }

    case actions.NOTIFACATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.GET_ALL_SETTING_REQUEST_SUCCESS:
      return {
        ...state,
        //success: action.success,
        message: action.message,
        loading: false,
        notifySettingList: action.data || []
      }
    case actions.SETTING_REQUEST_SUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        loading: false,
      }
    case actions.NOTIFACATION_SENDSUCCESS:
      return {
        ...state,
        pushSuccess: action.success,
        pushMessage: action.message,
        loading: false
      }
    case actions.NOTIFY_ERROR:
      return { 
        ...state, 
        ...initState, 
        message: action.message 
      }
    case actions.RESET_STATE_NOTIFY:
      return {
        ...state,
        pushSuccess: false,
        pushMessage: '',
        success: false,
        message: '',
      }
    default:
      return state;
  }
}
