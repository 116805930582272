import { serialize } from '../../utils/serialize'
import superFetch from '../../helpers/superFetch';

export const grtCheckService = async (props) => {
    try {
        return await superFetch.get(`/api/guarantor_verification?${serialize(props)}`);
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}


export const getService = async (props) => {
    try {
        return await superFetch.get(`/api/guarantor_notification_doc?${serialize(props)}`)
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const updateDeliveryNoService = async (props) => {
    try {
        return await superFetch.put(`/api/guarantor_notification_doc/delivery`, { ...props });
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const updateResponseNoService = async (props) => {
    try {
        return await superFetch.put(`/api/guarantor_notification_doc/response`, { ...props })
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const updateNoteService = async (props) => {
    try {
        return await superFetch.put(`/api/guarantor_notification_doc/note`, { ...props })
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}

export const printGrtService = async (props) => {
    try {
        return await superFetch.put(`/api/guarantor_notification_doc/print`, { ...props });
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
    }
}