import superFetch from "../../helpers/superFetch";

export const updatePermissionSevice = async (formdata) => {
    try {
      return await superFetch.put(`/api/permission`, { ...formdata });
    } catch (err) {
      return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
    }
  }

  export const getPermissionService = async () => {
    try {
      return await superFetch.get(`/api/permission`);
    } catch (error) {
      return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
    }
  }