import Auth from './auth/reducer';
import getPolicy from './statement/reducer';
import getUser from './manageUsers/reducer';
import getRequest from './request/reducer';
import getChart from './Chart/reducer';
import getListdata from './Dashboard/reducer';
import notification from './notification/reducer';
import manageNews from './manageNews/reducer';
import permission from './Permission/reducer'
import accruedInterestReducer from './report/Debtor/reducers'
import companyReducer from './company/reducers'
import guarantorReducer from './guarantor/reducers'
import debtorReducer from './debtor/reducers'
import debtorReducer2 from "./debtor2/reducers";
import checkContractAgeReducer from './contract/reducers'
import settingReducers from './setting/reducers'
import blacklistReducers from './blacklist/reducers'
import gradeReducers from './CustomerGrade/reducers';
import loginLocationReducer from './loginLocation/reducers'
import gradeConditionMgntReducers from './GradeConditionMgnt/reducers';
import conditionRangeMgntReducers from './ConditionRangeMgnt/reducers';
import adminWizardReducers from './AdminWizard/reducers'
import connectUserToCompanyReducer from './UserConnectCompany/reducers'
import gbPrimePayReducers from './GBPrimePay/reducers'

export default {
  Auth,
  getPolicy,
  getUser,
  getRequest,
  getChart,
  getListdata,
  notification,
  manageNews,
  permission,
  accruedInterestReducer,
  companyReducer,
  guarantorReducer,
  debtorReducer,
  debtorReducer2,
  checkContractAgeReducer,
  settingReducers,
  blacklistReducers,
  gradeReducers,
  loginLocationReducer,
  gradeConditionMgntReducers,
  conditionRangeMgntReducers,
  adminWizardReducers,
  connectUserToCompanyReducer,
  gbPrimePayReducers
};