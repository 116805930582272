import React from 'react';
import { message, Popconfirm } from 'antd'

export const clearSideBar = () => {
  localStorage.removeItem('sideBar');
}

export const matchOnlyNumber = (str) => /\d/.test(str);

export function getSideBar() {
  try {
    return JSON.parse(localStorage.getItem('sideBar'));
  } catch (err) {
    clearSideBar();
    return null;
  }
}
export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => num > 9 ? '' + num : '0' + num;
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function showError(word) {
  message.error(word);
}

export function showOk(word) {
  message.success(word);
}

export function PopconfirmDelete(props) {
  return (
    <Popconfirm
      title="คุณแน่ใจแล้วหรือไม่ที่ต้องการลบข้อมูลนี้?"
      onConfirm={props.onConfirm}
      okText="ใช่"
      cancelText="ไม่"
    >
      {props.children}
    </Popconfirm>
  )
}