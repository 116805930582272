import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import services from './services'

function* changeMode() {
    yield takeEvery(actions.CHANGE_MODE_REQ, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(services.changeModeService, props.mode),
                timeout: call(delay, 10000)
            });

            const { success } = res;
            if (success && !timeout) {
                message.success(res.message);
                yield put({ type: actions.UPDATE_MODE, sessionName: res.sessionName, sideBar: res.sideBar });
                yield put({ type: actions.CHANGE_MODE_SUCCESS });
                props.onSuccess();
            } else {
                message.error(res.message);
                yield put({ type: actions.CHANGE_MODE_ERROR });
            }
        } catch (error) {
            message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
            yield put({ type: actions.CHANGE_MODE_ERROR });
        }
    })
};

function* getDatabase() {
    yield takeEvery(actions.GET_DB, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(services.getDatabaseService),
                timeout: call(delay, 15000)
            });

            const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: actions.GET_DB_SUCCESS, data });
            } else {
                message.error(res.message);
                yield put({ type: actions.GET_DB_ERROR });
            }
        } catch (error) {
            message.error('ไม่สามารถทำรายการได้');
            yield put({ type: actions.GET_DB_ERROR });
        }
    })
}

export default function* databaseSaga() {
    yield all([
        fork(getDatabase),
        fork(changeMode)
    ])
}