import actions from "./actions";

const {
  GET_SYS_INFO,
  GET_SYS_INFO_SUCCESS,
  GET_SYS_INFO_ERROR,

  GET_NOTIFY_FORM,
  GET_NOTIFY_FORM_SUCCESS,
  GET_NOTIFY_FORM_ERROR,

  GET_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_ERROR,
  GET_BANK_ACCOUNT_SUCCESS,

  CREATE_BANK_ACCOUNT,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_ERROR,

  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_ERROR
} = actions;

const initState = {
  getBankAccountLoading: false,
  createBankAccountLoading: false,
  updateBankAccountLoading: false,

  bankAccountList: null,

  getLoading: false,
  getFormLoading: false,
  grtFormData: null,
  debFormData: null,
  variableDefinitionGrt: null,
  variableDefinitionDeb: null,
  data: null
};

export default function settingReducer(state = initState, action) {
  switch (action.type) {
    case GET_BANK_ACCOUNT:
      return {
        ...state,
        getBankAccountLoading: true
      };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        getBankAccountLoading: false,
        bankAccountList: action.data
      };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        getBankAccountLoading: false
      };

    case GET_NOTIFY_FORM:
      return {
        ...state,
        getFormLoading: true
      };
    case GET_NOTIFY_FORM_SUCCESS:
      return {
        ...state,
        getFormLoading: false,
        grtFormData:
          "grtFormData" in action ? action.grtFormData : state.grtFormData,
        debFormData:
          "debFormData" in action ? action.debFormData : state.debFormData,
        variableDefinitionDeb:
          "variableDefinitionDeb" in action
            ? action.variableDefinitionDeb
            : state.variableDefinitionDeb,
        variableDefinitionGrt:
          "variableDefinitionGrt" in action
            ? action.variableDefinitionGrt
            : state.variableDefinitionGrt
      };
    case GET_NOTIFY_FORM_ERROR:
      return {
        ...state,
        getFormLoading: false
      };

    case GET_SYS_INFO:
      return {
        ...state,
        getLoading: true
      };
    case GET_SYS_INFO_SUCCESS:
      return {
        ...state,
        getLoading: false,
        data: action.data
      };
    case GET_SYS_INFO_ERROR:
      return {
        ...state,
        getLoading: false
      };
    default:
      return state;
  }
}
