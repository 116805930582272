import { all, takeEvery, put, call, race, fork, select } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import { getGradeItemService, getGradeConditionService, updateGradeItemService } from './services'

const { GET_GRADE_ITEM, GET_GRADE_ITEM_SUCCESS, GET_GRADE_ITEM_ERROR, GET_CONDITION_ITEM, GET_CONDITION_ITEM_ERROR, GET_CONDITION_ITEM_SUCCESS, UPDATE_GRADE_ITEM, UPDATE_GRADE_ITEM_ERROR, UPDATE_GRADE_ITEM_SUCCESS } = actions;

function* getGradeItemGradeWorker() {
    yield takeEvery(GET_GRADE_ITEM, function* ({ gradeName }) {
        try {
            const { res, timeout } = yield race({
                res: call(getGradeItemService, gradeName),
                timeout: call(delay, 15000)
            });

            const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: GET_GRADE_ITEM_SUCCESS, data });
            } else {
                message.error(res.message);
                yield put({ type: GET_GRADE_ITEM_ERROR, data });
            }
        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GET_GRADE_ITEM_ERROR });
        }
    })
}

function* getGradeConditionWorker() {
    yield takeEvery(GET_CONDITION_ITEM, function* ({ gradeId }) {
        try {
            const { res, timeout } = yield race({
                res: call(getGradeConditionService, gradeId),
                timeout: call(delay, 15000)
            });

              const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: GET_CONDITION_ITEM_SUCCESS, data });
            } else {
                message.error(res.message);
                yield put({ type: GET_CONDITION_ITEM_ERROR });
            }
        } catch (error) {
           message.error('ไม่สามารถทำรายการได้');
           yield put({ type: GET_CONDITION_ITEM_ERROR });
        }
    })
}

function* updateGradeItemWorker() {
    yield takeEvery(UPDATE_GRADE_ITEM, function* ({ gradeId }) {
        try {
            const gradeCondition = yield select((state) => state.gradeConditionMgntReducers.gradeCondition);
            const conditionIdList = gradeCondition.filter(elem => elem.value).map(elem => elem.conditionId);
            const payload = { gradeId, conditionIdList };
            const { res, timeout } = yield race({
                res: call(updateGradeItemService, payload),
                timeout: call(delay, 15000)
            });

              const { success } = res;
            if (!timeout && success) {
                message.success(res.message);
                yield put({ type: UPDATE_GRADE_ITEM_SUCCESS });
            } else {
                message.error(res.message);
                yield put({ type: UPDATE_GRADE_ITEM_ERROR });
            }
        } catch (error) {
           message.error('ไม่สามารถทำรายการได้');
           yield put({ type: UPDATE_GRADE_ITEM_ERROR });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getGradeItemGradeWorker),
        fork(getGradeConditionWorker),
        fork(updateGradeItemWorker)
    ])
}