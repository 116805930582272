const debtorActions = {
    GET_OPTION: 'GET_OPTION',
    GET_OPTION_SUCCESS: 'GET_OPTION_SUCCESS',
    GET_OPTION_ERROR: 'GET_OPTION_ERROR',

    Get_Accrued_Interest_Report: 'Get_Accrued_Interest_Report',
    Get_Accrued_Interest_Report_Success: 'Get_Accrued_Interest_Report_Success',
    Get_Accrued_Interest_Report_Error: 'Get_Accrued_Interest_Report_Error',

    debtor_ranking: 'debtor_ranking',

    getDebtorRanking: props => ({ type: debtorActions.debtor_ranking, props }),
    getOptions: () => ({ type: debtorActions.GET_OPTION }),
    GetAccruedInterestReport: (props) => ({ type: debtorActions.Get_Accrued_Interest_Report, props })
}

export default debtorActions