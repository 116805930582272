import { enquireScreen } from 'enquire-js';
import { Layout } from 'antd';
import DrawerMenu from 'rc-drawer-menu';
import React, { Component } from 'react';

import 'antd/dist/antd.css';
import 'rc-drawer-menu/assets/index.css';

import './style';
import AppRouter from './AppRouter';
import Header from '../../components/Header';
import SideBar from '../Sidebar/Sidebar';
import ErrorBoundary from '../../helpers/ErrorBoundary'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  state = {
    collapsed: false,
    isMobile
  };

  componentDidMount() {
    enquireScreen((mobile) => {
      this.setState({ isMobile: mobile });
    });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    const { url } = this.props.match;
    const { collapsed } = this.state;
    const marginLeft = this.state.isMobile ? 0 : collapsed ? 0 : 200
    return (
      <Layout>
        {this.state.isMobile
          ? <DrawerMenu
            parent={null}
            level={null}
            iconChild={null}
            open={collapsed}
            onMaskClick={() => this.toggle()}
            width="200px"
          >
            <SideBar
              isMobile={this.state.isMobile}
              collapsed={this.state.isMobile
                ? false
                : collapsed}
              url={url}
            />
          </DrawerMenu>
          : <SideBar collapsed={collapsed} url={url} />
        }

        <Layout style={{ marginLeft }}>
          <Header collapsed={collapsed} handleToggle={this.toggle} />
          <ErrorBoundary>
            <AppRouter url={url} />
          </ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}
export default App;
