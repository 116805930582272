import actions from "./actions";

const {
  CREATE_BLACKLIST,
  CREATE_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST_ERROR,

  GET_BLACKLIST_OPT,
  GET_BLACKLIST_OPT_SUCCESS,
  GET_BLACKLIST_OPT_ERROR,

  GET_BLACKLIST,
  GET_BLACKLIST_SUCCESS,
  GET_BLACKLIST_ERROR,
  
  GET_BLACKLIST_LOGS,
  GET_BLACKLIST_LOGS_SUCCESS,
  GET_BLACKLIST_LOGS_ERROR,

  SEARCH_BLACKLIST_REQUEST,
  SEARCH_BLACKLIST_SUCCESS,
  SEARCH_BLACKLIST_ERROR
} = actions;

const initState = {
  createLoading: false,
  getOptLoading: false,
  getBlackListLoading: false,
  blackListType: [],
  blackListData: [],
  status: [],
  numOfDataBlackList: 0,
  getBlLogsLoading: false,
  blacklistLogs: [],
  blackListQuery: []
};

export default function blacklistReducers(state = initState, action) {
  switch (action.type) {
    case GET_BLACKLIST_LOGS:
      return {
        ...state,
        getBlLogsLoading: true
      };
    case GET_BLACKLIST_LOGS_SUCCESS:
      return {
        ...state,
        getBlLogsLoading: false,
        blacklistLogs: action.data
      };
    case GET_BLACKLIST_LOGS_ERROR:
      return {
        ...state,
        getBlLogsLoading: false
      };

    case GET_BLACKLIST:
      return {
        ...state,
        getBlackListLoading: true
      };
    case GET_BLACKLIST_SUCCESS:
      return {
        ...state,
        getBlackListLoading: false,
        blackListData: action.data,
        numOfDataBlackList: action.numOfData
      };
    case GET_BLACKLIST_ERROR:
      return {
        ...state,
        getBlackListLoading: false
      };

    case GET_BLACKLIST_OPT:
      return {
        ...state,
        getOptLoading: true
      };
    case GET_BLACKLIST_OPT_SUCCESS:
      return {
        ...state,
        getOptLoading: false,
        blackListType: action.blackListType,
        blackListStatus: action.blackListStatus
      };
    case GET_BLACKLIST_OPT_ERROR:
      return {
        ...state,
        getOptLoading: false
      };

    case CREATE_BLACKLIST:
      return {
        ...state,
        createLoading: true
      };
    case CREATE_BLACKLIST_SUCCESS:
      return {
        ...state,
        createLoading: false
      };
    case CREATE_BLACKLIST_ERROR:
      return {
        ...state,
        createLoading: false
      };
      case SEARCH_BLACKLIST_SUCCESS: 
      return {
        ...state,
        blackListQuery: action.data
      }
    default:
      return state;
  }
}
