import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import { getCustomerGradeService, getGradeConditionService } from './services'

const { GET_GRADE, GET_GRADE_ERROR, GET_GRADE_SUCCESS, GET_BLACKLIST, GET_GRADE_CONDITION, GET_GRADE_CONDITION_SUCCESS, GET_GRADE_CONDITION_ERROR } = actions;

function* getCustomerGradeWorker() {
    yield takeEvery(GET_GRADE, function* ({ IDCNO }) {
        try {
            const { res, timeout } = yield race({
                res: call(getCustomerGradeService, IDCNO),
                timeout: call(delay, 15000)
            });

            const { success, data, isBlackList } = res;
            if (!timeout && success) {
                if (isBlackList) {
                    message.error(res.message);
                    yield put({ type: GET_BLACKLIST, data });
                } else {
                    yield put({ type: GET_GRADE_SUCCESS, data });
                }
            } else {
                message.error(res.message);
                yield put({ type: GET_GRADE_ERROR, data });
            }
        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GET_GRADE_ERROR });
        }
    })
}

function* getGradeConditionWorker() {
    yield takeEvery(GET_GRADE_CONDITION, function* (type) {
        try {
            const { res, timeout } = yield race({
                res: call(getGradeConditionService),
                timeout: call(delay, 15000)
            });

            const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: GET_GRADE_CONDITION_SUCCESS, data });
            } else {
                message.error(res.message);
            }
        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GET_GRADE_CONDITION_ERROR });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getCustomerGradeWorker),
        fork(getGradeConditionWorker)
    ])
}