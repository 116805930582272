import {
  all,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import {
  delay
} from 'redux-saga';
import { message} from 'antd'

import actions from './actions';
import { 
  notifacationService, 
  userService, 
  getSettingsService, 
  settingNotifyService,
  getUserNotifyService,
 } from './services';

export function* getUserNotifacationList() {
  while (true) {
    try {
      const { page, userID } = yield take(actions.GET_NOTIFY_LIST_REQUEST) //ดัก action ALLUSER
      //yield delay(1500)
      const { res, timeout } = yield race({ res: call(getUserNotifyService, page, userID), timeout: call(delay, 10000) })
      const { success, data, numOfNotify, message } = res
      //SET_NUM_NOTIFY
      if (success && !timeout) {
        yield put({ type: actions.GET_NOTIFY_LIST_REQUEST_SUCCESS, data, numOfNotify })
      } else {
        yield put({ type: actions.NOTIFY_ERROR, message })
      }
    } catch (err) {
      yield put({ type: actions.NOTIFY_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' })
    }
  }
}

export function* postNotifacation() {
  while (true) {
    try {
      const { userID, notifyType, title, description } = yield take(actions.NOTIFACATION_REQUEST) //ดัก action ALLUSER
      //yield delay(1500)
      const { res, timeout } = yield race({ res: call(notifacationService, userID, notifyType, title, description), timeout: call(delay, 10000) })
      const { success } = res
      if (success && !timeout) {
        message.success(res.message)
        yield put({
          type: actions.NOTIFACATION_SENDSUCCESS,
          success,
          message: res.message
        });
      } else {
        message.error(res.message)
        yield put({
          type: actions.NOTIFY_ERROR,
          message: res.message
        })
      }
    } catch (err) {
      yield put({ type: actions.NOTIFY_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' })
    }
  }
}

export function* getUsers() {
  try {
    yield take(actions.NOTIFACATION_GETUSER) //ดัก action ALLUSER
    const { responsUsers, timeout } = yield race({ responsUsers: call(userService), timeout: call(delay, 10000) })
    const { success, data } = responsUsers;
    if (success && !timeout) {
      yield put({ type: actions.NOTIFACATION_GETUSER, payload: data });
    } else {
      yield put({ type: actions.NOTIFACATION_ERROR });
    }
  } catch (err) {
    yield put({ type: actions.NOTIFACATION_ERROR });
  }
}

export function* getSettingsNotifacation() {
  while (true) {
    try {
      yield take(actions.GET_ALL_SETTING_REQUEST) //ดัก action ALLUSER
      //yield delay(1500)
      const { res, timeout } = yield race({ res: call(getSettingsService), timeout: call(delay, 10000) })
      const { success, message, data } = res
      if (success && !timeout) {
        yield put({
          type: actions.GET_ALL_SETTING_REQUEST_SUCCESS,
          data,
          message
        });
      } else {
        yield put({
          type: actions.NOTIFY_ERROR,
          message
        })
      }
    } catch (err) {
      yield put({ type: actions.NOTIFY_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' })
    }
  }
}

export function* settingNotifacation() {
  while (true) {
    try {
      const { msgID, title, desc } = yield take(actions.SETTING_REQUEST) //ดัก action ALLUSER
      const { res, timeout } = yield race({ res: call(settingNotifyService, msgID, title, desc), timeout: call(delay, 10000) })
      const { success, message } = res
      if (success && !timeout) {
        yield put({
          type: actions.SETTING_REQUEST_SUCCESS,
          success,
          message
        }); 
        //yield put(push('/dashboard/notification'))
      } else {
        yield put({
          type: actions.NOTIFY_ERROR,
          success,
          message
        })
      }
    } catch (err) {
      yield put({ type: actions.NOTIFY_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false })
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(postNotifacation),
    fork(getUsers),
    fork(getSettingsNotifacation),
    fork(settingNotifacation),
    fork(getUserNotifacationList)
]);
}
