import { takeEvery, call, put, race, all, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import services from './services';
import { message } from 'antd';
import actions from './actions'

function* getGbPrimePay() {
    yield takeEvery(actions.GET_GB_PRIME_PAY, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(services.getGbPrimePayService),
                timeout: call(delay, 15000)
            });

            if (res.success && !timeout) {
                yield put({ type: actions.GET_GB_PRIME_PAY_SUCCESS, data: res.data });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            message.error(error.message);
            yield put({ type: actions.GET_GB_PRIME_PAY_ERROR });
        }
    })
}

export default function* () {
    yield all([
        fork(getGbPrimePay)
    ])
}