import actions from './actions';

const initState = {
    numPolicy: null, 
    numAmount: null,
    numDuePayment: null, 
    lateAmountPayment: null, 
    numRequest: null,
    Loading: false,

}; //init state

export default function deshboardReducer(state = initState, action) {
    switch (action.type) {
        case actions.DASHBOARD_LISTDATA_DASHBOARD:
            return {
                ...state,
                Loading: true,
                success: false,
                message: ''
            };
        case actions.DASHBOARD_LISTDATA_DASHBOARD_SUCCESS:
            return {
                ...state,
                Loading: false,
                numPolicy: action.numPolicy,
                numAmount: action.numAmount,
                numDuePayment: action.numDuePayment,
                lateAmountPayment: action.lateAmountPayment,
                numRequest: action.numRequest,
                success: action.success,
            };
       
        case actions.DASHBOARD_ERROR:
            return {
                ...state,
                ...initState,
                success: action.success,
                message: action.message
            };
        default:
            return state;
    }
}
