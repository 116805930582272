import superFetch from '../../helpers/superFetch';
import { serialize } from '../../utils/serialize';

export const policyActiveService = async (page, IDCNO) => {
  const { current, pageSize } = page;
  const doc = { IDCNO, status: 'open' };
  return superFetch.get(`/api/statements?${serialize({ current, pageSize, ...doc })}`);
}

export const policyInactiveService = async (page, IDCNO) => {
  const { current, pageSize } = page;
  const doc = { IDCNO, status: 'close' };
  return superFetch.get(`/api/statements?${serialize({ current, pageSize, ...doc })}`);
}