const statementActons = {
  STATEMENT_POLICYACTIVE: 'STATEMENT_POLICYACTIVE',
  STATEMENT_POLICYACTIVE_SUCCESS: 'STATEMENT_POLICYACTIVE_SUCCESS',
  STATEMENT_POLICYINACTIVE: 'STATEMENT_POLICYINACTIVE',
  STATEMENT_POLICYINACTIVE_SUCCESS: 'STATEMENT_POLICYINACTIVE_SUCCESS',
  STATEMENT_ERROR: 'STATEMENT_ERROR',
  getpolicyActive: (props) => ({
    type: statementActons.STATEMENT_POLICYACTIVE,
    ...props
  }),
  getpolicyInactive: (props) => ({
    type: statementActons.STATEMENT_POLICYINACTIVE,
    ...props
  }),
  statementError: () => ({
    type: statementActons.STATEMENT_ERROR,
  }),
};
export default statementActons;
