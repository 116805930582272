import actions from './actions';

const initState = {
  users: [],
  success: false,
  message: '',
  Loading: false,
  ListEmpAdmins: [],
  countEmp: 0,
  numOfUsers: 0,
  jobNameList: [],
  permissionList: [],
  loading: false,
}; //init state

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.ADD_USER || actions.EDIT_USER:
      return {
        ...state,
        loading: true
      };
    case actions.ADD_USER_SUCCESS || actions.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actions.ADD_USER_ERROR || actions.EDIT_USER_ERROR:
      return {
        ...state,
        loading: false
      };

    case actions.EDIT_USERS_ADMIN:
      return {
        ...state,
        Loading: true,
        success: false,
        message: ""
        //ListEmpAdmins: []
      };

    case actions.DELETE_ADMIN_USER:
      return {
        ...state,
        Loading: true
      };

    case actions.DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: action.success,
        message: action.message
      };

    case actions.EDIT_USERS_ADMIN_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: action.success,
        message: action.message
      };

    case actions.USER_LISTALLUSER:
      return {
        ...state,
        Loading: true
      };
    case actions.USER_LISTALLUSER_SUCCESS:
      return {
        ...state,
        Loading: false,
        users: action.payload,
        numOfUsers: action.numOfUsers
      };
    case actions.EMP_LISTADMINUSER:
      return {
        ...state,
        Loading: true
      };
    case actions.EMP_LISTADMINUSER_SUCCESS:
      return {
        ...state,
        Loading: false,
        jobNameList: action.jobNameList,
        permissionList: action.permissionList
      };
    case actions.USER_LISTALLUSERADMIN:
      return {
        ...state,
        Loading: true,
        success: false,
        message: ""
      };
    case actions.USER_LISTALLUSERADMIN_SUCCESS:
      return {
        ...state,
        Loading: false,
        ListEmpAdmins: action.data ? action.data : state.ListEmpAdmins,
        countEmp: action.numOfEmp,
        jobNameList: action.jobNameList,
        permissionList: action.permissionList
      };
    case actions.USER_COUNTEMP:
      return {
        ...state,
        numOfEmp: action.numOfEmp
      };
    case actions.USER_RESETPASSWORDEMP:
      return {
        ...state,
        Loading: true
      };
    case actions.USER_RESETPASSWORDEMP_SUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        Loading: false
      };
    case actions.USER_RESETPASSWORD:
      return {
        ...state,
        Loading: true
      };
    case actions.USER_RESETSUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        Loading: false
      };
    case actions.USER_RESETIDCNO:
      return {
        ...state,
        Loading: true
      };
    case actions.USER_RESETIDCNOSUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        Loading: false
      };
    case actions.USER_ADDEMPLOYEE:
      return {
        ...state,
        Loading: true
      };
    case actions.USER_ADDEMPLOYEE_SUCCESS:
      return {
        ...state,
        success: action.success,
        message: action.message,
        Loading: false
      };

    case actions.USER_RESET_STATE:
      return {
        ...state,
        ...initState
      };
    case actions.USER_ERROR:
      return {
        ...state,
        Loading: false,
        //...initState,
        success: action.success,
        message: action.message
      };
    default:
      return state;
  }
}
