const authActons = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_REQUEST: 'LOGOUT-REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT-SUCCESS',
  LOGOUT_ERROR: 'LOGOUT-ERROR',
 
  RESET_STATE: 'RESET_STATE',

  ME_REQUEST: 'ME_REQUEST',
  ME_SUCCESS: 'ME_SUCCESS',
  ME_ERROR: 'ME_ERROR',
  
  Unauthorized: 'Unauthorized',
  Forbidden: 'Forbidden',

  getMe: () => ({
    type: authActons.ME_REQUEST
  }),

  resetState: () => ({
    type: authActons.RESET_STATE,
  }),
  login: (username, password) => ({
    type: authActons.LOGIN_REQUEST,
    username,
    password
  }),
  logout: () => ({
    type: authActons.LOGOUT_REQUEST,
  }),
};
export default authActons;
