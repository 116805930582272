const requestActons = {
  AC_REQUEST_DATA: 'REQUEST_DATA',
  AC_REQUEST_DATA_SUCCESS: 'REQUEST_DATA_SUCCESS',
  IN_REQUEST_DATA: 'IN_REQUEST_DATA',
  IN_REQUEST_DATA_SUCCESS: 'IN_REQUEST_DATA_SUCCESS',
  REQUEST_ERROR: 'REQUEST_ERROR',
  CHANGE_STATUS_REQUEST: 'CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_REQUEST_SUCCESS: 'CHANGE_STATUS_REQUEST_SUCCESS',
  RESET_STATE: 'RESET_STATE',
  
  changeStatusRequest: (reqID, status, improvDesc) => ({
    type: requestActons.CHANGE_STATUS_REQUEST,
    reqID,
    status,
    improvDesc
  }),
  reSetState: () => ({
    type: requestActons.RESET_STATE,
  }),
  getInRequests: (props) => ({
    type: requestActons.IN_REQUEST_DATA,
    props
  }),
  getAcRequests: (props) => ({
    type: requestActons.AC_REQUEST_DATA,
    props
  }),
  requesError: () => ({
    type: requestActons.REQUEST_ERROR,
  }),
};
export default requestActons;
