import actions from './actions';

const initState = {
    loading: false,
    databaseList: []
}
export default function(state= initState, action) {
    switch (action.type) {
        case actions.GET_DB:
            return {
                ...state,
                loading: true
            }
        case actions.GET_DB_SUCCESS: 
        return {
            ...state,
            databaseList: action.data,
            loading: false
        }
        case actions.GET_DB_ERROR:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        default:
            return state;
    }
}