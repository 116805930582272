import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import asyncComponent from "./helpers/AsyncFunc";
import App from "./containers/App/App";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PublicRoutes = ({ isLoggedIn, history }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path="/"
          component={asyncComponent(() => import("./containers/auth"))}
        />
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

const mapStateToProps = ({ Auth }) => {
  const { isLoggedIn } = Auth;
  return {
    isLoggedIn,
  };
};

export default connect(mapStateToProps, null)(PublicRoutes);
