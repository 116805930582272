const actions = {
    CHECK_CONTRACT_AGE: 'CHECK_CONTRACT_AGE',
    CHECK_CONTRACT_AGE_SUCCESS: 'CHECK_CONTRACT_AGE_SUCCESS',
    CHECK_CONTRACT_AGE_ERROR: 'CHECK_CONTRACT_AGE_ERROR',

    checkContractAge: (props) => ({
        type: actions.CHECK_CONTRACT_AGE,
        props
    })
}

export default actions 