
import actions from './actions';

const {
    GET_DEB_DOCS,
    GET_DEB_DOCS_SUCCESS,
    GET_DEB_DOCS_ERROR,
    UPDATE_DEB_DELIVERY_NO, UPDATE_DEB_DELIVERY_NO_SUCCESS, UPDATE_DEB_DELIVERY_NO_ERROR
} = actions

const initState = {
    getLoading: false,
    debNotifyLists: [],
    numOfData: 0
};

export default function debNotiReducer(state = initState, action) {
    switch (action.type) {
        case GET_DEB_DOCS:
            return {
                ...state,
                getLoading: true
            };
        case GET_DEB_DOCS_SUCCESS:
            return {
                ...state,
                getLoading: false,
                debNotifyLists: action.data,
                numOfData: action.numOfData
            };
        case GET_DEB_DOCS_ERROR:
            return {
                ...state,
                getLoading: false
            };

        default:
            return state;
    }
}
