import {
  all,
  takeEvery,
  put,
  fork,
  call,
  take,
  race
} from "redux-saga/effects";
import { message } from "antd";
import { delay } from "redux-saga";

import {
  userService,
  resetPWService,
  resetIDCNOService,
  employeesService,
  empPermissionListService,
  empJobnameListService,
  editUserAdminService,
  deleteAdminUserService,
  resetpwdEmpService,
  addEmpService,
  addUserService,
  editUserService,
  deleteUserService
} from "./services";
import actions from "./actions";

export function* getUsers() {
  while (true) {
    try {
      const { props } = yield take(actions.USER_LISTALLUSER);
      const { responsUsers, timeout } = yield race({
        responsUsers: call(userService, props),
        timeout: call(delay, 15000)
      });

      const { success, data, numOfUsers } = responsUsers;
      if (success && !timeout) {
        yield put({
          type: actions.USER_LISTALLUSER_SUCCESS,
          payload: data,
          numOfUsers
        });
      } else {
        message.error(responsUsers.message);
        yield put({ type: actions.USER_ERROR, message: responsUsers.message });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
      yield put({
        type: actions.USER_ERROR,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* deleteAdminUser() {
  while (true) {
    try {
      const { empID } = yield take(actions.DELETE_ADMIN_USER); //ดัก action ALLUSER

      const { res, timeout } = yield race({
        res: call(deleteAdminUserService, empID),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message)
        yield put({
          type: actions.DELETE_ADMIN_USER_SUCCESS,
          message: res.message
        });
      } else {
        message.error(res.message)
        yield put({ type: actions.USER_ERROR, success });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้')
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* editAdminUser() {
  while (true) {
    try {
      const { props, redirect } = yield take(actions.EDIT_USERS_ADMIN); //ดัก action ALLUSER
      //yield delay(1500)
      const { responsUsers, timeout } = yield race({
        responsUsers: call(editUserAdminService, props),
        timeout: call(delay, 15000)
      });
      const { success } = responsUsers;
      if (success && !timeout) {
        message.success(responsUsers.message);
        yield put({ type: actions.EDIT_USERS_ADMIN_SUCCESS, success });
        redirect();
      } else {
        message.error(responsUsers.message);
        yield put({ type: actions.USER_ERROR, success });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* getemployees() {
  while (true) {
    try {
      const { props } = yield take(actions.USER_LISTALLUSERADMIN);
      //yield delay(1500)
      const { responsUsers, timeout } = yield race({
        responsUsers: all([
          call(employeesService, props),
          call(empPermissionListService),
          call(empJobnameListService)
        ]),
        timeout: call(delay, 15000)
      });

      const { success, data, numOfEmp, message } = responsUsers[0]
      const permission = responsUsers[1]
      const jobName = responsUsers[2]

      if (success && !timeout) {
        yield put({
          type: actions.USER_LISTALLUSERADMIN_SUCCESS,
          data,
          numOfEmp,
          jobNameList: jobName.data,
          permissionList: permission.data
        });
      } else {
        yield put({ type: actions.USER_ERROR, success, message });
      }
    } catch (err) {
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* getJopEmp() {
  while (true) {
    try {
      yield take(actions.EMP_LISTADMINUSER);
      const { response, timeout } = yield race({
        response: all([
          call(empJobnameListService),
          call(empPermissionListService)
        ]),
        timeout: call(delay, 15000)
      });
      const jobName = response[0];
      const permission = response[1];

      if (jobName.success && permission.success && !timeout) {
        yield put({
          type: actions.EMP_LISTADMINUSER_SUCCESS,
          jobNameList: jobName.data,
          permissionList: permission.data
        });
      } else {
        if (!jobName.success) {
          yield put({
            type: actions.USER_ERROR,
            success: jobName.success,
            message: jobName.message
          });
        }
        if (!permission.success) {
          yield put({
            type: actions.USER_ERROR,
            success: permission.success,
            message: permission.message
          });
        }
      }
    } catch (err) {
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* addEmployees() {
  while (true) {
    try {
      const { empRegisterDatas, redirect } = yield take(actions.USER_ADDEMPLOYEE);
      const { response, timeout } = yield race({
        response: call(addEmpService, empRegisterDatas),
        timeout: call(delay, 15000)
      });
      const { success } = response;
      if (success && !timeout) {
        message.success(response.message)
        yield put({
          type: actions.USER_ADDEMPLOYEE_SUCCESS,
          success
        });
        redirect();
      } else {
        message.error(response.message)
        yield put({ type: actions.USER_ERROR, success });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* resetpwdEMP() {
  while (true) {
    try {
      const { empID, newPassword, confirmPassword } = yield take(
        actions.USER_RESETPASSWORDEMP
      );
      yield delay(1500);
      const { response, timeout } = yield race({
        response: call(resetpwdEmpService, empID, newPassword, confirmPassword),
        timeout: call(delay, 15000)
      });
      const { success, message } = response;
      console.log(response);
      if (success && !timeout) {
        yield put({
          type: actions.USER_RESETPASSWORDEMP_SUCCESS,
          success,
          message
        });
      } else {
        yield put({ type: actions.USER_ERROR, success, message });
      }
    } catch (err) {
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* resetPWD() {
  while (true) {
    try {
      const { userID, password, confirmPassword } = yield take(
        actions.USER_RESETPASSWORD
      );
      //yield delay(1500)
      const { responsUsers, timeout } = yield race({
        responsUsers: call(resetPWService, userID, password, confirmPassword),
        timeout: call(delay, 15000)
      });
      const { success, message } = responsUsers;
      if (success && !timeout) {
        yield put({
          type: actions.USER_RESETSUCCESS,
          success,
          message
        });
      } else {
        yield put({ type: actions.USER_ERROR, success, message });
      }
    } catch (err) {
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* resetIDCNO() {
  while (true) {
    try {
      const { userID, IDCNO } = yield take(actions.USER_RESETIDCNO);
      //yield delay(1500)
      const { responsUsers, timeout } = yield race({
        responsUsers: call(resetIDCNOService, userID, IDCNO),
        timeout: call(delay, 15000)
      });
      const { success, message } = responsUsers;
      if (success && !timeout) {
        yield put({
          type: actions.USER_RESETIDCNOSUCCESS,
          success,
          message
        });
      } else {
        yield put({ type: actions.USER_ERROR, success, message });
      }
    } catch (err) {
      yield put({
        type: actions.USER_ERROR,
        success: false,
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้"
      });
    }
  }
}

export function* addUser() {
  yield takeEvery(actions.ADD_USER, function* ({ props }) {
    const { data, onSuccess } = props;
    try {
      const { res, timeout } = yield race({
        res: call(addUserService, data),
        timeout: call(delay, 15000)
      });

      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: actions.ADD_USER_SUCCESS });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: actions.ADD_USER_ERROR });
    }
  });
}


export function* editUser() {
  yield takeEvery(actions.EDIT_USER, function* ({ props }) {
    const { data, onSuccess } = props;
    try {
      const { res, timeout } = yield race({
        res: call(editUserService, data),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        yield put({ type: actions.EDIT_USER_SUCCESS });
        onSuccess()
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: actions.EDIT_USER_ERROR });
    }
  });
}


export function* deleteUserWorker() {
  yield takeEvery(actions.DELETE_USER, function* ({ props }) {
    const { userID, onSuccess } = props;
    try {
      const { res, timeout } = yield race({
        res: call(deleteUserService, userID),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: actions.DELETE_USER_SUCCESS });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: actions.DELETE_USER_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(deleteUserWorker),
    fork(editUser),
    fork(addUser),
    fork(getUsers),
    fork(getemployees),
    fork(addEmployees),
    fork(resetpwdEMP),
    fork(resetPWD),
    fork(resetIDCNO),
    fork(getJopEmp),
    fork(editAdminUser),
    fork(deleteAdminUser)
  ]);
}
