import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import { grtCheckService, getService, updateDeliveryNoService, updateResponseNoService, updateNoteService, printGrtService } from './services'
import { makeblob } from '../../utils/makeBlob'

const {
    GET_GRT, GET_GRT_SUCCESS, GET_GRT_ERROR,
    UPDATE_DELIVERY_NO,
    UPDATE_RESPONSE_NO,
    UPDATE_NOTE,
    PRINT_GRT_DOCS,
    GRT_CHECKING,
    GRT_CHECKING_SUCCESS,
    GRT_CHECKING_ERROR
} = actions

export function* getWorker() {
    yield takeEvery(GET_GRT, function* ({ current, pageSize, endDateDelivered, startDateDelivered, totalOutstandingdays, Ku_ID, deliveryNo, deliveriesStatus, alreadyPrint, isThereRespponseNo, daysOfOutstandingBeforeDeliverd, note }) {
        try {
            const { res, timeout } = yield race({
                res: call(getService, { current, pageSize, endDateDelivered, startDateDelivered, Ku_ID, deliveryNo, totalOutstandingdays, deliveriesStatus, alreadyPrint, isThereRespponseNo, daysOfOutstandingBeforeDeliverd, note }),
                timeout: call(delay, 15000)
            })
            const { success, data, numOfData } = res
            if (success && !timeout) {
                yield put({ type: GET_GRT_SUCCESS, data, numOfData })
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GET_GRT_ERROR });
        }
    })
}

export function* grtCheckWorker() {
    yield takeEvery(GRT_CHECKING, function* ({ props }) {
        try {
            const { res, timeout } = yield race({
                res: call(grtCheckService, props),
                timeout: call(delay, 15000)
            })
            const { success, data, totalArrears, totalAmount, avgArrears } = res
            if (success && !timeout) {
                yield put({ type: GRT_CHECKING_SUCCESS, data, totalArrears, totalAmount, avgArrears })
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: GRT_CHECKING_ERROR });
        }
    })
}

export function* updateDeliveryNoWorker() {
    yield takeEvery(UPDATE_DELIVERY_NO, function* ({ transportedBy, notificationDocId, deliveryNo, deliveryDate, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(updateDeliveryNoService, { transportedBy, notificationDocId, deliveryNo, deliveryDate }),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                message.success('Update เรียบร้อย');
                onSuccess()
                // yield put({ type: UPDATE_DELIVERY_NO_SUCCESS, data })
                // console.log('getWorker data ', data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
        }
    })
}

export function* updateResponseNoWorker() {
    yield takeEvery(UPDATE_RESPONSE_NO, function* ({ notificationDocId, responseNo, responseDate, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(updateResponseNoService, { notificationDocId, responseNo, responseDate }),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                message.success('Update เรียบร้อย');
                onSuccess()
                // yield put({ type: UPDATE_DELIVERY_NO_SUCCESS, data })
                // console.log('getWorker data ', data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
        }
    })
}

export function* updateNoteWorker() {
    yield takeEvery(UPDATE_NOTE, function* ({ notificationDocId, note, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(updateNoteService, { notificationDocId, note }),
                timeout: call(delay, 15000)
            })
            const { success } = res
            if (success && !timeout) {
                message.success('Update เรียบร้อย');
                onSuccess()
                // yield put({ type: UPDATE_DELIVERY_NO_SUCCESS, data })
                // console.log('getWorker data ', data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
        }
    })
}

export function* printWorker() {
    yield takeEvery(PRINT_GRT_DOCS, function* ({ policyNoList, onSuccess }) {
        try {
            const { res, timeout } = yield race({
                res: call(printGrtService, { policyNoList }),
                timeout: call(delay, 15000)
            })
            const { success, data } = res
            if (success && !timeout) {
                message.success('Update เรียบร้อย');
                onSuccess()
                // yield put({ type: UPDATE_DELIVERY_NO_SUCCESS, data })
                const fileURL = URL.createObjectURL(makeblob(data.base64))
                window.open(fileURL)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error('ไม่สามารถทำรายการได้')
            // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
        }
    })
}


export default function* rootSagas() {
    yield all([
        fork(getWorker),
        fork(updateDeliveryNoWorker),
        fork(updateResponseNoWorker),
        fork(updateNoteWorker),
        fork(printWorker),
        fork(grtCheckWorker)
    ])
}