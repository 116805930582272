import { all, takeEvery, put, fork, call, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { message } from 'antd';
import { getDebtorRanking, getCompanySevice, getAccTypeSevice, getAccruedInterestReportSevice } from './services'
import actions from './actions'

function* getOptionsWorker() {
    try {
        yield takeEvery(actions.GET_OPTION, function* () {
            const { res: { company, accType }, timeout } =
                yield race({
                    res: all({ company: call(getCompanySevice), accType: call(getAccTypeSevice) }), timeout: call(delay, 10000)
                })
            if ((company.success && accType.success) && !timeout) {
                yield put({ type: actions.GET_OPTION_SUCCESS, companyList: company.data, accountType: accType.data })
            } else {
                yield put({ type: actions.GET_OPTION_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
            }
        })
    } catch (err) {
        yield put({ type: actions.GET_OPTION_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
}

function* GetAccruedInterestReport() {
    try {
        yield takeEvery(actions.Get_Accrued_Interest_Report, function* ({ props }) {
            const { res, timeout } = yield race({ res: call(getAccruedInterestReportSevice, props), timeout: call(delay, 25000) })
            const { data, numOfData } = res
            if (res.success && !timeout) {
                message.success(`พบ ${numOfData} ข้อมูล`)
                const link = document.createElement('a');
                link.href = data;
                link.download = `${props.company} Debtor Accrued.pdf`;
                link.click();
                yield put({ type: actions.GET_OPTION })
            } else {
                message.error(res.message);
            }
        })
    } catch (error) {
        console.log(error)
        message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้')
    }
}

function* getDebtorRankingWK() {
    try {
        yield takeEvery(actions.debtor_ranking, function* ({ props }) {
            const { res, timeout } = yield race({ res: call(getDebtorRanking, props), timeout: call(delay, 25000) })
            const { data, numOfData } = res
            if (res.success && !timeout) {
                message.success(`พบ ${numOfData} ข้อมูล`)
                const link = document.createElement('a')
                link.href = data;
                link.download = `${props.company} Debtor Ranking.pdf`
                link.click();
            } else {
                message.error(res.message);
            }
        })
    } catch (error) {
        message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้')
    }
}

export default function* rootSaga() {
    yield all([
        fork(GetAccruedInterestReport),
        fork(getOptionsWorker),
        fork(getDebtorRankingWK)
    ])
}