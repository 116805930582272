import { all, takeEvery, put, call, race, fork } from "redux-saga/effects";
import { message } from "antd";
import { delay } from "redux-saga";
import actions from "./actions";
import { getLoginLocationService } from "./services";

const {
  GET_LOGIN_LOCATION,
  GET_LOGIN_LOCATION_SUCCESS,
  GET_LOGIN_LOCATION_ERROR
} = actions;

export function* getLoginLocationWorker() {
  yield takeEvery(GET_LOGIN_LOCATION, function*({ props }) {
    try {
      const { res, timeout } = yield race({
        res: call(getLoginLocationService, props),
        timeout: call(delay, 15000)
      });
      const { success, data, numOfData } = res;
      if (success && !timeout) {
        yield put({ type: GET_LOGIN_LOCATION_SUCCESS, data, numOfData });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_LOGIN_LOCATION_ERROR });
    }
  });
}

export default function* rootSagas() {
  yield all([fork(getLoginLocationWorker)]);
}
