import actions from './actions';

const initState = {
    loading: false,
    conditionRangeData: undefined,
    comparisonOperatorList: undefined
}

export default function conditionRageMgntReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_CONDITION_RANGE:
            return { ...state, loading: true };
        case actions.GET_CONDITION_RANGE_SUCCESS:
            return { ...state, loading: false, conditionRangeData: action.data };
        case actions.GET_CONDITION_RANGE_ERROR:
            return { ...state, loading: false };
        case actions.GET_COMPARISON_OPERATOR:
            return { ...state, loading: true };
        case actions.GET_COMPARISON_OPERATOR_SUCCESS:
            return { ...state, comparisonOperatorList: action.data, loading: false };
        case actions.GET_COMPARISON_OPERATOR_ERROR:
            return { ...state, comparisonOperatorList: action.data, loading: false };
        case actions.EDIT_CONDITION_RANGE:
            return { ...state, loading: true };
        case actions.EDIT_CONDITION_RANGE_SUCCESS:
            return { ...state, loading: false };
        case actions.EDIT_CONDITION_RANGE_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
}