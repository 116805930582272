import actions from './actions';
import Cookies from '../../helpers/cookies';
import { getSideBar, showError, showOk } from '../../helpers/utility';
import adminWizardAction from '../AdminWizard/actions'

const cookie = new Cookies();

const initState = {
  isLoggedIn: cookie.get('isLoggedIn'),
  loading: false,
  message: null,
  sideBar: getSideBar(),
  name: localStorage.getItem('name'),
  permission: localStorage.getItem('permission'),
  sessionName: localStorage.getItem('sessionName') || 'None'
}; //init state

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      localStorage.setItem('sideBar', JSON.stringify(action.sideBar));
      localStorage.setItem('name', action.name);
      localStorage.setItem('permission', action.permission);
      localStorage.setItem('sessionName', action.sessionName);

      return {
        ...state,
        loading: false,
        isLoggedIn: cookie.get('isLoggedIn'),
        sideBar: action.sideBar,
        name: action.name,
        permission: action.permission,
        sessionName: action.sessionName
      };
    case adminWizardAction.UPDATE_MODE:
      localStorage.setItem('sideBar', JSON.stringify(action.sideBar));
      localStorage.setItem('sessionName', action.sessionName);
      return {
        ...state,
        sessionName: action.sessionName,
        sideBar: action.sideBar
      }
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        isLoggedIn: null
      };
    case actions.LOGOUT_REQUEST: {
      return {
        ...initState,
        loading: true
      }
    }
    case actions.LOGOUT_SUCCESS:
      localStorage.clear();

      return {
        ...initState,
        isLoggedIn: undefined,
        loading: false,
        message: action.message,
      };
    case actions.LOGOUT_ERROR: {
      localStorage.clear();

      return {
        ...initState,
        loading: false,
        message: action.message
      }
    }
    case actions.ME_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.ME_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        me: action.data
      }
    }
    case actions.ME_ERROR: {
      return {
        ...initState,
        loading: false,
        isLoggedIn: false,
        me: undefined
      }
    }
    case actions.Unauthorized: {
      showError(action.message);

      localStorage.clear();
      return { ...initState }
    }
    case actions.Forbidden: {
      showError(action.message);
      
      return { ...state }
    }
    default:
      return state;
  }
}
