import { message } from 'antd'
import {
  all,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import {
  delay
} from 'redux-saga';
import actions from './actions';
import { acRequestService, inRequestService, changeStatusService } from './services'

export function* getAcRequests() {
  while (true) {
    try {
      const { props } = yield take(actions.AC_REQUEST_DATA) //ดัก action ALLUSER
      yield delay(1500)
      const { responsRequests, timeout } = yield race({ responsRequests: call(acRequestService, props), timeout: call(delay, 15000) })
      const { success, data, numOfReq, message } = responsRequests;
      if (success && !timeout) {
        yield put({ type: actions.AC_REQUEST_DATA_SUCCESS, data, numOfReq });
      } else {
        yield put({ type: actions.REQUEST_ERROR, success, message });
      }
    } catch (err) {
      yield put({ type: actions.REQUEST_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}

export function* getInRequests() {
  while (true) {
    try {
      const { props } = yield take(actions.IN_REQUEST_DATA) //ดัก action ALLUSER
      yield delay(1500)
      const { responsRequests, timeout } = yield race({ responsRequests: call(inRequestService, props), timeout: call(delay, 15000) })
      const { success, data, numOfReq, message } = responsRequests;
      if (success && !timeout) {
        yield put({ type: actions.IN_REQUEST_DATA_SUCCESS, data, numOfReq });
      } else {
        yield put({ type: actions.REQUEST_ERROR, success, message });
      }
    } catch (err) {
      yield put({ type: actions.REQUEST_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}


export function* changeStatus() {
  while (true) {
    try {
      const { reqID, status, improvDesc } = yield take(actions.CHANGE_STATUS_REQUEST) //ดัก action ALLUSER
      //yield delay(1500)
      const { responsRequests, timeout } = yield race({ responsRequests: call(changeStatusService, reqID, status, improvDesc), timeout: call(delay, 15000) })
      const { success, message } = responsRequests;
      if (success && !timeout) {
        yield put({ type: actions.CHANGE_STATUS_REQUEST_SUCCESS, success });
      } else {
        yield put({ type: actions.REQUEST_ERROR, success, message });
      }
    } catch (err) {
      yield put({ type: actions.REQUEST_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(getAcRequests),
    fork(getInRequests),
    fork(changeStatus)
  ]);
}
