import superFetch from "../../helpers/superFetch";
import { serialize } from "../../utils/serialize";

export const acRequestService = (props) => {
  const { page: { current, pageSize }, userID } = props;
  return superFetch.get(`/api/complaint?${serialize({ current, pageSize, userID, status: 'AC' })}`);
}

export const inRequestService = (props) => {
  const { page: { current, pageSize }, userID } = props;
  return superFetch.get(`/api/complaint?${serialize({ current, pageSize, userID, status: 'IN' })}`);
}

export const changeStatusService = (reqID, status, improvDesc = '') => {
  return superFetch.put(`/api/complaint`, { reqID, status, improvDesc });
}