const chartActons = {
    GETALL_STATS_CHART_REQUEST : 'GETALL_STATS_CHART_REQUEST',
    GETALL_STATS_CHART_REQUEST_SUCCESS: 'GETALL_STATS_CHART_REQUEST_SUCCESS',
    GETALL_STATS_CHART_REQUEST_ERROR: 'GETALL_STATS_CHART_REQUEST_ERROR',

    getAllStats: () => ({
      type: chartActons.GETALL_STATS_CHART_REQUEST,
    })
  };
  export default chartActons;
  