const permActions = {
    GET_PERM_REQUEST: 'GET_PERM_REQUEST',
    GET_PREM_SUCCESS: 'GET_PREM_SUCCESS',
    GET_PERM_ERROR: 'GET_PERM_ERROR',
    UPDATE_PERM_REQUEST: 'UPDATE_PERM_REQUEST',
    UPDATE_PERM_TMPDATA: 'UPDATE_PERM_TMPDATA',
    UPDATE_PERM_SUCCESS: 'UPDATE_PERM_SUCCESS',
    UPDATE_PERM_ERROR: 'UPDATE_PERM_ERROR',
    getPermission: () => ({ type: permActions.GET_PERM_REQUEST }),
    updatePermission: (formData) => ({ type: permActions.UPDATE_PERM_REQUEST, formData })
}

export default permActions;