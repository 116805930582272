class Cookies {
    setCookie(key, value, option) {
        document.cookie = `${key}=${value}`;
    }

    removeCookie(key, option) {

    }

    get(key, option) {
        const regexp = new RegExp(`.*${key}=([^;]*)`);
        const result = regexp.exec(document.cookie);

        if (result) {
            return result[1];
        }
    }

    getAll(option) {

    }
}

export default Cookies;