import superFetch from "../../helpers/superFetch";
import config from '../../configs';

const { apiUrl } = config;

export const newsService = async () => {
  try {
    return await superFetch.get(`/api/news`);
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}

export const deleteNewsService = async (newsID) => {
  try {
    return await superFetch.delete(`/api/news/`, { newsID });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}

export const addNewsService = async (link, photo, slide) => {
  try {
    const formData = new FormData();
    formData.append('photo', photo[0].originFileObj);
    formData.append('link', link);
    formData.append('slide', slide);
    const response = await fetch(`${apiUrl}/api/news`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      },
      method: 'POST',
      body: formData
    })
    return await response.json();
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}
