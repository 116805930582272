const actions = {
    GET_CONDITION_RANGE: 'get-condition-range',
    GET_CONDITION_RANGE_ERROR: 'get-condition-range-error',
    GET_CONDITION_RANGE_SUCCESS: 'get-condition-range-success',

    EDIT_CONDITION_RANGE: 'edit-condition-range',
    EDIT_CONDITION_RANGE_ERROR: 'edit-condition-range-error',
    EDIT_CONDITION_RANGE_SUCCESS: 'edit-condition-range-success',

    GET_COMPARISON_OPERATOR: 'GET_COMPARISON_OPERATOR',
    GET_COMPARISON_OPERATOR_ERROR: 'GET_COMPARISON_OPERATOR_ERROR',
    GET_COMPARISON_OPERATOR_SUCCESS: 'GET_COMPARISON_OPERATOR_SUCCESS',

    getAllConditionRange: () => {
        return { type: actions.GET_CONDITION_RANGE }
    },

    editConditionRange: (props) => {
        return { type: actions.EDIT_CONDITION_RANGE, props };
    },

    getComparisonOperator: () => {
        return { type: actions.GET_COMPARISON_OPERATOR }
    }
};

export default actions;