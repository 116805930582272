
import actions from './actions';

const {
  GET_DEB_DOCS_2,
  GET_DEB_DOCS_SUCCESS_2,
  GET_DEB_DOCS_ERROR_2,
  UPDATE_DEB_DELIVERY_NO_2,
  UPDATE_DEB_DELIVERY_NO_SUCCESS_2,
  UPDATE_DEB_DELIVERY_NO_ERROR_2
} = actions;

const initState = {
    getLoading: false,
    debNotifyLists: [],
    numOfData: 0
};

export default function debNotiReducer2(state = initState, action) {
    switch (action.type) {
      case GET_DEB_DOCS_2:
        return {
          ...state,
          getLoading: true
        };
      case GET_DEB_DOCS_SUCCESS_2:
        return {
          ...state,
          getLoading: false,
          debNotifyLists: action.data,
          numOfData: action.numOfData
        };
      case GET_DEB_DOCS_ERROR_2:
        return {
          ...state,
          getLoading: false
        };

      default:
        return state;
    }
}
