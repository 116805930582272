import config from '../../configs'
import superFetch from '../../helpers/superFetch'

export const signinEmpService = async (username, password) => {
  try {
    return await superFetch.post('/api/emp/signin', { username, password });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}

export const getMeService = () => superFetch.get(`/api/auth/me`);

export const signoutEmpService = async () => {
  try {
    return await superFetch.get(`/api/emp/signout`)
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
  }
}