import { serialize } from "../../utils/serialize";
import superFetch from "../../helpers/superFetch";

export const getLoginLocationService = async props => {
  try {
    return await superFetch.get(`/api/locations?${serialize(props)}`);
  } catch (err) {
    return {
      message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      success: false
    };
  }
};
