const guarantorActions = {
  GET_DEB_DOCS_2: "GET_DEB_DOCS_2",
  GET_DEB_DOCS_SUCCESS_2: "GET_DEB_DOCS_SUCCESS_2",
  GET_DEB_DOCS_ERROR_2: "GET_DEB_DOCS_ERROR_2",

  UPDATE_DEB_DELIVERY_NO_2: "UPDATE_DEB_DELIVERY_NO_2",
  UPDATE_DEB_DELIVERY_NO_SUCCESS_2: "UPDATE_DEB_DELIVERY_NO_SUCCESS_2",
  UPDATE_DEB_DELIVERY_NO_ERROR_2: "UPDATE_DEB_DELIVERY_NO_ERROR_2",

  UPDATE_DEB_RESPONSE_NO_2: "UPDATE_DEB_RESPONSE_NO_2",
  UPDATE_DEB_RESPONSE_NO_SUCCESS_2: "UPDATE_DEB_RESPONSE_NO_SUCCESS_2",
  UPDATE_DEB_RESPONSE_NO_ERROR_2: "UPDATE_DEB_RESPONSE_NO_ERROR_2",

  UPDATE_DEB_NOTE_2: "UPDATE_DEB_NOTE_2",

  PRINT_DEB_DOCS_DOCS_2: "PRINT_DEB_DOCS_DOCS_2",

  getData: props => ({
    type: guarantorActions.GET_DEB_DOCS_2,
    ...props
  }),

  updateDeliveryNo: props => ({
    type: guarantorActions.UPDATE_DEB_DELIVERY_NO_2,
    ...props
  }),

  updateResponseNo: props => ({
    type: guarantorActions.UPDATE_DEB_RESPONSE_NO_2,
    ...props
  }),

  updateNoteNo: props => ({
    type: guarantorActions.UPDATE_DEB_NOTE_2,
    ...props
  }),

  printGrtDocs: props => ({
    type: guarantorActions.PRINT_DEB_DOCS_DOCS_2,
    ...props
  })
};

export default guarantorActions
