import { all, takeEvery, put, call, race, fork } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import {
    getService,
    updateDeliveryNoService,
    updateResponseNoService,
    updateNoteService,
    printGrtService
} from './services'
import { makeblob } from '../../utils/makeBlob'

const {
  GET_DEB_DOCS_2,
  GET_DEB_DOCS_SUCCESS_2,
  GET_DEB_DOCS_ERROR_2,
  UPDATE_DEB_DELIVERY_NO_2,
  UPDATE_DEB_RESPONSE_NO_2,
  UPDATE_DEB_NOTE_2,
  PRINT_DEB_DOCS_DOCS_2
} = actions;

export function* getDebWorker_2() {
         yield takeEvery(GET_DEB_DOCS_2, function*({
           current,
           pageSize,
           endDateDelivered,
           startDateDelivered,
           totalOutstandingdays,
           Ku_ID,
           deliveryNo,
           deliveriesStatus,
           alreadyPrint,
           isThereRespponseNo,
           daysOfOutstandingBeforeDeliverd,
           note,
           sendingStatus
         }) {
           try {
             const { res, timeout } = yield race({
               res: call(getService, {
                 current,
                 pageSize,
                 Ku_ID,
                 endDateDelivered,
                 startDateDelivered,
                 deliveryNo,
                 totalOutstandingdays,
                 deliveriesStatus,
                 alreadyPrint,
                 isThereRespponseNo,
                 daysOfOutstandingBeforeDeliverd,
                 note,
                 sendingStatus
               }),
               timeout: call(delay, 15000)
             });
             const { success, data, numOfData } = res;
             if (success && !timeout) {
               yield put({ type: GET_DEB_DOCS_SUCCESS_2, data, numOfData });
             } else {
               message.error(res.message);
             }
           } catch (e) {
             message.error("ไม่สามารถทำรายการได้");
             yield put({ type: GET_DEB_DOCS_ERROR_2 });
           }
         });
       }


export function* updateDebDeliveryNoWorker_2() {
         yield takeEvery(UPDATE_DEB_DELIVERY_NO_2, function*({
           transportedBy,
           notificationDocId,
           deliveryNo,
           deliveryDate,
           onSuccess
         }) {
           try {
             const { res, timeout } = yield race({
               res: call(updateDeliveryNoService, {
                 transportedBy,
                 notificationDocId,
                 deliveryNo,
                 deliveryDate
               }),
               timeout: call(delay, 15000)
             });
             const { success } = res;
             if (success && !timeout) {
               message.success("Update เรียบร้อย");
               onSuccess();
               // yield put({ type: UPDATE_DEB_DELIVERY_NO_SUCCESS, data })
               // console.log('getWorker data ', data)
             } else {
               message.error(res.message);
             }
           } catch (e) {
             message.error("ไม่สามารถทำรายการได้");
             // yield put({ type: UPDATE_DEB_DELIVERY_NO_ERROR });
           }
         });
       }

export function* updateDebResponseNoWorker_2() {
         yield takeEvery(UPDATE_DEB_RESPONSE_NO_2, function*({
           notificationDocId,
           responseNo,
           responseDate,
           onSuccess
         }) {
           try {
             const { res, timeout } = yield race({
               res: call(updateResponseNoService, {
                 notificationDocId,
                 responseNo,
                 responseDate
               }),
               timeout: call(delay, 15000)
             });
             const { success } = res;
             if (success && !timeout) {
               message.success("Update เรียบร้อย");
               onSuccess();
               // yield put({ type: UPDATE_DEB_DELIVERY_NO_SUCCESS, data })
               // console.log('getWorker data ', data)
             } else {
               message.error(res.message);
             }
           } catch (e) {
             message.error("ไม่สามารถทำรายการได้");
             // yield put({ type: UPDATE_DEB_DELIVERY_NO_ERROR });
           }
         });
       }

export function* updateDebNoteWorker_2() {
         yield takeEvery(UPDATE_DEB_NOTE_2, function*({
           notificationDocId,
           note,
           onSuccess
         }) {
           try {
             const { res, timeout } = yield race({
               res: call(updateNoteService, { notificationDocId, note }),
               timeout: call(delay, 15000)
             });
             const { success } = res;
             if (success && !timeout) {
               message.success("Update เรียบร้อย");
               onSuccess();
               // yield put({ type: UPDATE_DEB_DELIVERY_NO_SUCCESS, data })
               // console.log('getWorker data ', data)
             } else {
               message.error(res.message);
             }
           } catch (e) {
             message.error("ไม่สามารถทำรายการได้");
             // yield put({ type: UPDATE_DEB_DELIVERY_NO_ERROR });
           }
         });
       }

export function* printDebWorker_2() {
         yield takeEvery(PRINT_DEB_DOCS_DOCS_2, function*({
           policyNoList,
           onSuccess
         }) {
           try {
             const { res, timeout } = yield race({
               res: call(printGrtService, { policyNoList }),
               timeout: call(delay, 15000)
             });
             const { success, data } = res;
             if (success && !timeout) {
               message.success("Update เรียบร้อย");
               onSuccess();
               // yield put({ type: UPDATE_DEB_DELIVERY_NO_SUCCESS, data })
               const fileURL = URL.createObjectURL(makeblob(data.base64));
               window.open(fileURL);
             } else {
               message.error(res.message);
             }
           } catch (e) {
             message.error("ไม่สามารถทำรายการได้");
             // yield put({ type: UPDATE_DEB_DELIVERY_NO_ERROR });
           }
         });
       }


export default function* rootSagas() {
    yield all([
        fork(getDebWorker_2),
        fork(updateDebDeliveryNoWorker_2),
        fork(updateDebResponseNoWorker_2),
        fork(updateDebNoteWorker_2),
        fork(printDebWorker_2)
    ])
}