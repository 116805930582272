import actions from './actions';

const initialState = {
    gbPrimePayData: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_GB_PRIME_PAY:
            return {
                ...state,
                loading: true
            }
        case actions.GET_GB_PRIME_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
                gbPrimePayData: action.data
            }
        default:
            return state;
    }
}