import { connect } from "react-redux";
import { Layout, Icon, Modal, Menu, Dropdown, Spin, Typography } from "antd";
import { withRouter } from "react-router-dom";
import React from "react";
import "antd/dist/antd.css";
import authActions from "../../redux/auth/actions";
import adminWizardActions from "../../redux/AdminWizard/actions";
import "./style.css";

const { Header } = Layout;
const { Text } = Typography;
const confirm = Modal.confirm;
class Headers extends React.Component {
  constructor(props) {
    super(props);

    this.adminList = [
      <Menu.Item key="root">
        <a
          key="admin"
          onClick={this.gotoMode.bind(
            this,
            "Admin",
            "/dashboard/database-mgnt"
          )}
        >
          ผู้ดูแลระบบ
        </a>
      </Menu.Item>,
      <Menu.Divider key="divider" />,
    ];
  }

  componentDidMount() {
    if (this.props.permission === "Admin") {
      this.props.getAll();
    }
  }

  showConfirm() {
    confirm({
      title: "คุณต้องการออกจากระบบหรือไม่",
      onOk: () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.props.logout());
          }, 1000);
        });
      },
      onCancel() {},
    });
  }

  gotoMode(mode, goto) {
    this.props.changeMode(mode, () => {
      this.props.history.push(goto);
    });
  }

  render() {
    const {
      collapsed,
      handleToggle,
      name,
      permission,
      loading,
      dbList,
    } = this.props;

    const companyList = dbList.map((item) => {
      return (
        <Menu.Item key={item.id}>
          <a
            key={item.company_id}
            onClick={this.gotoMode.bind(
              this,
              item.company_id,
              "/dashboard/stat"
            )}
          >
            {item.companyName}
          </a>
        </Menu.Item>
      );
    });

    const menu = <Menu>{[...this.adminList, ...companyList]}</Menu>;

    return (
      <Spin spinning={loading}>
        <Header
          style={{
            background: "#fff",
            padding: 0,
          }}
        >
          <Icon
            style={{
              color: "red",
            }}
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={handleToggle}
          />
          {permission === "Admin" ? (
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Text strong>
                  Admin Wizard <Icon type="down" />
                </Text>
              </a>
            </Dropdown>
          ) : (
            ""
          )}

          <span style={{ color: "#da2d29", float: "right", paddingRight: 22 }}>
            <span>
              {name} {" | "}
              {permission} {" | "}
            </span>
            <a onClick={() => this.showConfirm()} style={{ color: "#da2d29" }}>
              Logout
            </a>{" "}
            <Icon
              style={{ fontSize: 20 }}
              onClick={() => this.showConfirm()}
              type="export"
            />
          </span>
        </Header>
      </Spin>
    );
  }
}

const mapStateToProps = ({ Auth, adminWizardReducers }) => {
  const { name, permission } = Auth;
  return {
    name,
    permission,
    dbList: adminWizardReducers.databaseList,
    loading: adminWizardReducers.loading,
  };
};

const { logout } = authActions;
const { getAll, changeMode } = adminWizardActions;
export default connect(mapStateToProps, { logout, getAll, changeMode })(
  withRouter(Headers)
);
