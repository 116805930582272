import superFetch from "../../../helpers/superFetch";

export const getCompanySevice = async () => {
    try {
        return await superFetch.get(`/api/companies/options`);
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
    }
}


export const getAccTypeSevice = () =>  superFetch.get(`/api/policy-type`)


export const getAccruedInterestReportSevice = async (
    { company, date, startOSB, endOSB, startAccruedInterest, endAccruedInterest, accountType }) => {
    try {
        return await superFetch.get(`/api/accrued-customer-account?company=${company}&date=${date}&startOSB=${startOSB}&endOSB=${endOSB}&startAccruedInterest=${startAccruedInterest}&endAccruedInterest=${endAccruedInterest}&accountType=${accountType}`)
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
    }
}


export const getDebtorRanking = async ({ company, accountType, endDate, startDate }) => {
    try {
        return await superFetch.get(`/api/debtor-ranking-report?company=${company}&startDate=${startDate}&endDate=${endDate}&accountType=${accountType}`);
    } catch (err) {
        return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' }
    }
}
