import React from "react";
import moment from "moment";
import { all, takeEvery, put, call, race, fork } from "redux-saga/effects";
import { Modal, Table } from "antd";
import { message } from "antd";
import { delay } from "redux-saga";
import actions from "./actions";
import {
  createService,
  getService,
  getOptService,
  undoReduService,
  updateNoteService,
  getBlLogsService,
  searchBlackListService
} from "./services";
const columnsLogs = [
  {
    title: "ประเภท",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "ผู้บันทึก",
    dataIndex: "blackListBy",
    key: "blackListBy"
  },
  {
    title: "หมายเหตุ",
    width: 250,
    dataIndex: "note",
    key: "note",
    render: text => (
      <span style={{ display: "block", wordWrap: "break-word", width: 250 }}>
        {text}
      </span>
    )
  },
  {
    title: "วันที่เวลา",
    dataIndex: "created",
    key: "created",
    render: text => moment.utc(text).format("DD/MM/YYYY HH:mm:ss")
  }
];
const {
  CREATE_BLACKLIST,
  CREATE_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST_ERROR,

  GET_BLACKLIST,
  GET_BLACKLIST_SUCCESS,
  GET_BLACKLIST_ERROR,

  GET_BLACKLIST_OPT,
  GET_BLACKLIST_OPT_SUCCESS,
  GET_BLACKLIST_OPT_ERROR,

  UNDOREDO_BLACKLIST,
  UNDOREDO_BLACKLIST_SUCCESS,
  UNDOREDO_BLACKLIST_ERROR,

  UPDATE_BL_NOTE,
  UPDATE_BL_SUCCESS,
  UPDATE_BL_ERROR,

  GET_BLACKLIST_LOGS,
  GET_BLACKLIST_LOGS_SUCCESS,
  GET_BLACKLIST_LOGS_ERROR,

  SEARCH_BLACKLIST_REQUEST,
  SEARCH_BLACKLIST_SUCCESS,
  SEARCH_BLACKLIST_ERROR
} = actions;

const columns = [
  {
    title: "ประเภท",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "ผู้บันทึก",
    dataIndex: "blackListBy",
    key: "blackListBy"
  },
  {
    title: "หมายเหตุ",
    width: 250,
    dataIndex: "note",
    key: "note",
    render: text => (
      <span style={{ display: "block", wordWrap: "break-word", width: 250 }}>
        {text}
      </span>
    )
  },
  {
    title: "วันที่เวลา",
    dataIndex: "created",
    key: "created",
    render: text => moment.utc(text).format("DD/MM/YYYY HH:mm:ss")
  }
];

//moment.utc(record.responseDate).format("DD/MM/YYYY")
export function* getBlackListLogsWorker() {
  yield takeEvery(GET_BLACKLIST_LOGS, function* ({ ID, logsOnly }) {
    try {
      const { res, timeout } = yield race({
        res: call(getBlLogsService, ID),
        timeout: call(delay, 15000)
      });
      const { success, data } = res;
      if (success && !timeout) {
        yield put({ type: GET_BLACKLIST_LOGS_SUCCESS, data });
        if (logsOnly) {
          return Modal.info({
            maskClosable: true,
            okText: "ปิด",
            width: "80%",
            title: "ประวัติการทำรายการ",
            content: (
              <div>
                <Table
                  scroll={{ x: 1100 }}
                  dataSource={data}
                  columns={columnsLogs}
                />
              </div>
            ),
            onOk() { }
          });
        }
      } else {
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_BLACKLIST_LOGS_ERROR });
    }
  });
}
export function* updateNoteWorker() {
  yield takeEvery(UPDATE_BL_NOTE, function* ({ props, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(updateNoteService, props),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: UPDATE_BL_SUCCESS });
      } else {
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: UPDATE_BL_ERROR });
    }
  });
}

export function* undoReduBlackListWorker() {
  yield takeEvery(UNDOREDO_BLACKLIST, function* ({ props, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(undoReduService, props),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: UNDOREDO_BLACKLIST_SUCCESS });
      } else {
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: UNDOREDO_BLACKLIST_ERROR });
    }
  });
}

export function* getListOfBlackListWorker() {
  yield takeEvery(GET_BLACKLIST, function* ({ props }) {
    try {
      const { res, timeout } = yield race({
        res: call(getService, props),
        timeout: call(delay, 15000)
      });
      const { success, data, numOfData } = res;
      if (success && !timeout) {
        yield put({ type: GET_BLACKLIST_SUCCESS, data, numOfData });
      } else {
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_BLACKLIST_ERROR });
    }
  });
}

export function* getBlackListOptWorker() {
  yield takeEvery(GET_BLACKLIST_OPT, function* ({ }) {
    try {
      const { res, timeout } = yield race({
        res: call(getOptService),
        timeout: call(delay, 15000)
      });
      const { success, status, type } = res;
      if (success && !timeout) {
        yield put({
          type: GET_BLACKLIST_OPT_SUCCESS,
          blackListStatus: status,
          blackListType: type
        });
      } else {
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_BLACKLIST_OPT_ERROR });
    }
  });
}

export function* createBlacklistWorker() {
  yield takeEvery(CREATE_BLACKLIST, function* ({ props, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(createService, props),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        yield put({ type: CREATE_BLACKLIST_SUCCESS });
        message.success(res.message);
        onSuccess();
        // yield put({ type: actions.GET });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: CREATE_BLACKLIST_ERROR });
    }
  });
}

export function* searchBlacklistWorker() {
  yield takeEvery(SEARCH_BLACKLIST_REQUEST, function* ({ keyword }) {
    try {
      const { res, timeout } = yield race({
        res: call(searchBlackListService, { keyword }),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        yield put({ type: SEARCH_BLACKLIST_SUCCESS, data: res.data });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: SEARCH_BLACKLIST_ERROR });
    }
  });
}

export default function* rootSagas() {
  yield all([
    fork(createBlacklistWorker),
    fork(getListOfBlackListWorker),
    fork(getBlackListOptWorker),
    fork(undoReduBlackListWorker),
    fork(updateNoteWorker),
    fork(getBlackListLogsWorker),
    fork(searchBlacklistWorker)
  ]);
}
