import {
  all,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import {
  delay
} from 'redux-saga';
import { message} from 'antd'
import actions from './actions';
import { push } from 'react-router-redux';
import { newsService, deleteNewsService, addNewsService } from './services'

export function* getNews() {
  while (true) {
    try {
      yield take(actions.NEWS_GETLISTDATA)
      const { res, timeout } = yield race({ res: call(newsService), timeout: call(delay, 15000) })
      const { success, data, message } = res;
      if (success && !timeout) {
        yield put({ type: actions.NEWS_GETLISTDATA_SUCCESS, data });
      } else {
        yield put({ type: actions.NEWS_ERROR });
      }
    } catch (err) {
      yield put({ type: actions.NEWS_ERROR });
    }
  }
}

export function* deleteNews() {
  while (true) {
    try {
      const { newsID } = yield take(actions.NEWS_DELETEDATA)
      const { res, timeout } = yield race({ res: call(deleteNewsService, newsID), timeout: call(delay, 15000) })
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        yield put({ type: actions.NEWS_DELETEDATA_SUCCESS, success, message: res.message });
        yield put({ type: actions.NEWS_GETLISTDATA });
      } else {
        message.error(res.message);
        yield put({ type: actions.NEWS_ERROR, success, message: res.message });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
      yield put({ type: actions.NEWS_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}

export function* addNews() {
  while (true) {
    try {
      const { link, photo, slide, redirect } = yield take(actions.NEWS_ADDNEWSPOMOSION) 
      const { res, timeout } = yield race({ res: call(addNewsService, link, photo, slide), timeout: call(delay, 15000) })
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        yield put({ type: actions.NEWS_ADDNEWSPOMOSION_SUCCESS, success, message: res.message  });
        redirect();
      } else {
        message.error(res.message);
        yield put({ type: actions.NEWS_ERROR, success, message: res.message  });
      }
    } catch (err) {
      message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
      yield put({ type: actions.NEWS_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(getNews),
    fork(deleteNews),
    fork(addNews),
  ]);
}
