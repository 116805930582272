
const actions = {
  GET_SYS_INFO: "GET_SYS_INFO",
  GET_SYS_INFO_SUCCESS: "GET_SYS_INFO_SUCCESS",
  GET_SYS_INFO_ERROR: "GET_SYS_INFO_ERROR",

  UPDATE_SYS_INFO: "UPDATE_SYS_INFO",

  GET_NOTIFY_FORM: "GET_NOTIFY_FORM",
  GET_NOTIFY_FORM_SUCCESS: "GET_NOTIFY_FORM_SUCCESS",
  GET_NOTIFY_FORM_ERROR: "GET_NOTIFY_FORM_ERROR",

  UPDATE_NOTIFY_FORM: "UPDATE_NOTIFY_FORM",

  GET_BANK_ACCOUNT: "GET_BANK_ACCOUNT",
  GET_BANK_ACCOUNT_SUCCESS: "GET_BANK_ACCOUNT_SUCCESS",
  GET_BANK_ACCOUNT_ERROR: "GET_BANK_ACCOUNT_ERROR",

  CREATE_BANK_ACCOUNT: "CREATE_BANK_ACCOUNT",
  CREATE_BANK_ACCOUNT_SUCCESS: "CREATE_BANK_ACCOUNT_SUCCESS",
  CREATE_BANK_ACCOUNT_ERROR: "UPDATE_BANK_ACCOUNT_ERROR",

  UPDATE_BANK_ACCOUNT: "UPDATE_BANK_ACCOUNT",
  UPDATE_BANK_ACCOUNT_SUCCESS: "UPDATE_BANK_ACCOUNT_SUCCESS",
  UPDATE_BANK_ACCOUNT_ERROR: "UPDATE_BANK_ACCOUNT_ERROR",

  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT_SUCCESS: "DELETE_BANK_ACCOUNT_SUCCESS",
  DELETE_BANK_ACCOUNT_ERROR: "DELETE_BANK_ACCOUNT_ERROR",

  getBankAccount: companyId => ({
    type: actions.GET_BANK_ACCOUNT,
    companyId
  }),
  createBankAccount: props => ({
    type: actions.CREATE_BANK_ACCOUNT,
    ...props
  }),
  updateBankAccount: props => ({
    type: actions.UPDATE_BANK_ACCOUNT,
    ...props
  }),
  deleteBankAccount: props => ({
    type: actions.DELETE_BANK_ACCOUNT,
    ...props
  }),

  getNotifyForm: props => ({
    type: actions.GET_NOTIFY_FORM,
    props
  }),

  updateNotifyFrom: (props, onSuccess) => ({
    type: actions.UPDATE_NOTIFY_FORM,
    props,
    onSuccess
  }),

  getSysInfo: () => ({
    type: actions.GET_SYS_INFO
  }),
  updateSysInfo: (props, onSuccess) => ({
    type: actions.UPDATE_SYS_INFO,
    props,
    onSuccess
  })
};

export default actions 