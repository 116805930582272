import {
  all,
  takeEvery,
  put,
  fork,
  call,
  take,
  race
} from 'redux-saga/effects';
import {
  delay
} from 'redux-saga';
import actions from './actions';
import {chartService} from './services';

export function* getAllStats() {
while (true) {
  try {
    yield take(actions.GETALL_STATS_CHART_REQUEST);
    //yield delay(1500)
    const { response, timeout } = yield race({response: call(chartService), timeout: call(delay, 15000)});
    const { success, message, genderStatList, distStatList, amountStatList, ageStatList } = response;

    if (success && !timeout) {
      yield put({type: actions.GETALL_STATS_CHART_REQUEST_SUCCESS,  genderStatList, distStatList, amountStatList, ageStatList });
    } else {
      yield put({type: actions.GETALL_STATS_CHART_REQUEST_ERROR, success, message });
    }
  } catch (err) {
    yield put({type: actions.GETALL_STATS_CHART_REQUEST_ERROR, success: false, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้' });
  }
 }
}

export function* statisticError() {
  yield takeEvery(actions.GETALL_STATS_CHART_REQUEST_ERROR, function * () {});
}

export default function * rootSaga() {
  yield all([
    fork(getAllStats), 
    fork(statisticError)]);
}
