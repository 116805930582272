const loginLocationActions = {
  GET_LOGIN_LOCATION: "GET_LOGIN_LOCATION",
  GET_LOGIN_LOCATION_SUCCESS: "GET_LOGIN_LOCATION_SUCCESS",
  GET_LOGIN_LOCATION_ERROR: "GET_LOGIN_LOCATION_ERROR",

  getLoginLocations: props => ({
    type: loginLocationActions.GET_LOGIN_LOCATION,
    props
  })
};

export default loginLocationActions;
