import { serialize } from '../../utils/serialize'
import superFetch from '../../helpers/superFetch';

export const getService = async (props) => {
  try {
    return await superFetch.get(`/api/debtor-notification-doc-2?${serialize(props)}`);
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
  }
}

export const updateDeliveryNoService = async (props) => {
  try {
    return await superFetch.put(`/api/debtor-notification-doc-2/delivery`, { ...props });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
  }
}

export const updateResponseNoService = async (props) => {
  try {
    return await superFetch.put(`/api/debtor-notification-doc-2/response`, { ...props });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
  }
}

export const updateNoteService = async (props) => {
  try {
    return await superFetch.put(`/api/debtor-notification-doc-2/note`, { ...props });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
  }
}

export const printGrtService = async (props) => {
  try {
    return await superFetch.put(
      `/api/debtor-notification-doc-2/print`, { ...props });
  } catch (err) {
    return { message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', success: false }
  }
}