
import actions from './actions';

const {
    GET_GRT,
    GET_GRT_SUCCESS,
    GET_GRT_ERROR,
    UPDATE_DELIVERY_NO, UPDATE_DELIVERY_NO_SUCCESS, UPDATE_DELIVERY_NO_ERROR,
    GRT_CHECKING,
    GRT_CHECKING_SUCCESS,
    GRT_CHECKING_ERROR
} = actions

const initState = {
    getLoading: false,
    guarantorLists: [],
    getCheckLists: [],
    grtCheckLoading: false,
    numOfData: 0,
    totalArrears: 0,
    totalAmount: 0, 
    avgArrears: 0
};

export default function guarantorReducer(state = initState, action) {
    switch (action.type) {
        case GET_GRT:
            return {
                ...state,
                getLoading: true
            };
        case GET_GRT_SUCCESS:
            return {
                ...state,
                getLoading: false,
                guarantorLists: action.data,
                numOfData: action.numOfData
            };
        case GET_GRT_ERROR:
            return {
                ...state,
                getLoading: false
            };

        case GRT_CHECKING:
            return {
                ...state,
                grtCheckLoading: true
            };
        case GRT_CHECKING_SUCCESS:
            return {
                ...state,
                grtCheckLoading: false,
                getCheckLists: action.data,
                totalArrears: action.totalArrears, 
                totalAmount: action.totalAmount, 
                avgArrears: action.avgArrears
            };
        case GRT_CHECKING_ERROR:
            return {
                ...state,
                grtCheckLoading: false
            };
        default:
            return state;
    }
}
