import { all, takeEvery, put, call, race, fork, select } from 'redux-saga/effects'
import { message } from 'antd'
import { delay } from 'redux-saga'
import actions from './actions'
import services from './services';

function* getAllConditionRange() {
    yield takeEvery(actions.GET_CONDITION_RANGE, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(services.getAllConditionRangeService),
                timeout: delay(15000)
            });

            const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: actions.GET_CONDITION_RANGE_SUCCESS, data });
            } else {
                message.error(res.message);
                yield put({ type: actions.GET_CONDITION_RANGE_ERROR, data });
            }

        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: actions.GET_CONDITION_RANGE_ERROR });
        }
    })
}

function* getComparisonOperator() {
    yield takeEvery(actions.GET_COMPARISON_OPERATOR, function* (props) {
        try {
            const { res, timeout } = yield race({
                res: call(services.getAllComparisonOperatorService),
                timeout: delay(15000)
            });

            const { success, data } = res;
            if (!timeout && success) {
                yield put({ type: actions.GET_COMPARISON_OPERATOR_SUCCESS, data });
            } else {
                message.error(res.message);
                yield put({ type: actions.GET_COMPARISON_OPERATOR_ERROR, data });
            }

        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: actions.GET_COMPARISON_OPERATOR_ERROR });
        }
    })
}

function* editConditionRange() {
    yield takeEvery(actions.EDIT_CONDITION_RANGE, function* ({ props }) {
        try {
            const { res, timeout } = yield race({
                res: call(services.editComparisonOperatorService, props),
                timeout: delay(15000)
            });

            const { success } = res;
            if (!timeout && success) {
                message.success(res.message);
                yield put({ type: actions.EDIT_CONDITION_RANGE_SUCCESS });
            } else {
                message.error(res.message);
                yield put({ type: actions.EDIT_CONDITION_RANGE_ERROR });
            }

        } catch (error) {
            message.error('ไม่สามารถทำรายการได้')
            yield put({ type: actions.EDIT_CONDITION_RANGE_ERROR });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getAllConditionRange),
        fork(getComparisonOperator),
        fork(editConditionRange)
    ])
}