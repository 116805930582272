import { all, takeEvery, put, fork, call, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { getPermissionService, updatePermissionSevice } from './services'
import { message } from 'antd'
import actions from './actions'

function* getPermissionRequest() {
    try {
        yield takeEvery(actions.GET_PERM_REQUEST, function* () {
            const { res: { success, data, message }, timeout } = yield race({ res: call(getPermissionService), timeout: call(delay, 10000) })
             if (success && !timeout) {
                yield put({ type: actions.GET_PREM_SUCCESS, data })
            } else {
                yield put({ type: actions.GET_PERM_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้'});
            }
        })
    } catch (err) {
        yield put({ type: actions.GET_PERM_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้'});
    }
}

function* updatePermission() {
    try {
        yield takeEvery(actions.UPDATE_PERM_REQUEST, function* (formData) {
            const { formData: data } = formData;
            const { res, timeout } = yield race({ res: call(updatePermissionSevice, data), timeout: call(delay, 10000) })
             if (res.success && !timeout) {
                 message.success(res.message);
                yield put({ type: actions.UPDATE_PERM_SUCCESS })
            } else {
                message.error(res.message);
                yield put({ type: actions.UPDATE_PERM_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้'});
            }
        })
    } catch (err) {
        message.error('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
        yield put({ type: actions.UPDATE_PERM_ERROR, message: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้'});
    }
}

export default function* rootSaga () {
    yield all([
        fork(getPermissionRequest),
        fork(updatePermission)
    ])
}