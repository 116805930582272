import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import usersMangement from './manageUsers/saga';
import statemantSagas from './statement/saga';
import requestSagas from './request/saga';
import chartSagas from './Chart/saga';
import DashboardSagas from './Dashboard/saga';
import notificationSagas from './notification/saga';
import manageNewsSagas from './manageNews/saga'
import permissionSagas from './Permission/saga'
import accruedInterest from './report/Debtor/saga'
import companySagas from './company/sagas'
import guarantorSagas from './guarantor/sagas'
import debtorSagas from './debtor/sagas'
import debtorSagas2 from "./debtor2/sagas";

import checkContractAgeSagas from './contract/sagas'
import settingSagas from './setting/sagas'
import blackListSagas from './blacklist/sagas'
import gradeCustomer from './CustomerGrade/sagas'
import loginLocation from './loginLocation/sagas'
import gradeConditionMgnt from './GradeConditionMgnt/sagas'
import conditionRangeMgnt from './ConditionRangeMgnt/sagas'
import adminWizardSagas from './AdminWizard/sagas'
import connectUserSagas from './UserConnectCompany/sagas'
import gbPrimePaySagas from './GBPrimePay/sagas'

export default function* rootSaga() {
  yield all([
    authSagas(),
    connectUserSagas(),
    usersMangement(),
    statemantSagas(),
    requestSagas(),
    chartSagas(),
    DashboardSagas(),
    notificationSagas(),
    manageNewsSagas(),
    permissionSagas(),
    accruedInterest(),
    companySagas(),
    guarantorSagas(),
    debtorSagas(),
    debtorSagas2(),
    checkContractAgeSagas(),
    settingSagas(),
    blackListSagas(),
    gradeCustomer(),
    loginLocation(),
    gradeConditionMgnt(),
    conditionRangeMgnt(),
    adminWizardSagas(),
    gbPrimePaySagas()
  ]);
}
