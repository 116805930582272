import { notification } from 'antd'
import { store } from '../redux/store';
import actions from '../redux/auth/actions';
import config from '../configs';

class SuperFetch {
    constructor() {
        this.fetchObj = {};
        this.methods = ['get', 'post', 'put', 'patch', 'delete'];
        this.bindMethod();
    }

    bindMethod() {
        this.methods.forEach(method => {
            this.fetchObj[method] = this.fetch.bind(this, method);
        });
    }

    async fetch(method, path, bodyObj) {
        try {
            const response = await fetch(`${config.apiUrl}${path}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                method,
                body: method !== 'get' ? JSON.stringify(bodyObj) : undefined
            });

            const json = await response.json();
            if (response.status === 401) {
                store.dispatch({ type: actions.Unauthorized, message: json.message });                  
                window.location.replace('/');
                return json;
            } else if (response.status === 403) {
                store.dispatch({ type: actions.Forbidden, message: json.message });
                return json;
            } else {
                return json;
            }
        } catch (error) {
            throw error;
        }
    }

    get instance() {
        return this.fetchObj;
    }
}

export default (new SuperFetch()).instance;
