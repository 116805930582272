const guarantorActions = {
    GET_DEB_DOCS: 'GET_DEB_DOCS',
    GET_DEB_DOCS_SUCCESS: 'GET_DEB_DOCS_SUCCESS',
    GET_DEB_DOCS_ERROR: 'GET_DEB_DOCS_ERROR',

    UPDATE_DEB_DELIVERY_NO: 'UPDATE_DEB_DELIVERY_NO',
    UPDATE_DEB_DELIVERY_NO_SUCCESS: 'UPDATE_DEB_DELIVERY_NO_SUCCESS',
    UPDATE_DEB_DELIVERY_NO_ERROR: 'UPDATE_DEB_DELIVERY_NO_ERROR',

    UPDATE_DEB_RESPONSE_NO: 'UPDATE_DEB_RESPONSE_NO',
    UPDATE_DEB_RESPONSE_NO_SUCCESS: 'UPDATE_DEB_RESPONSE_NO_SUCCESS',
    UPDATE_DEB_RESPONSE_NO_ERROR: 'UPDATE_DEB_RESPONSE_NO_ERROR',

    UPDATE_DEB_NOTE: 'UPDATE_DEB_NOTE',

    PRINT_DEB_DOCS_DOCS: 'PRINT_DEB_DOCS_DOCS',

    getData: (props) => ({
        type: guarantorActions.GET_DEB_DOCS,
        ...props
    }),

    updateDeliveryNo: (props) => ({
        type: guarantorActions.UPDATE_DEB_DELIVERY_NO,
        ...props
    }),

    updateResponseNo: (props) => ({
        type: guarantorActions.UPDATE_DEB_RESPONSE_NO,
        ...props
    }),
    
    updateNoteNo: (props) => ({
        type: guarantorActions.UPDATE_DEB_NOTE,
        ...props
    }),

    printGrtDocs: (props) => ({
        type: guarantorActions.PRINT_DEB_DOCS_DOCS,
        ...props
    })
}

export default guarantorActions
