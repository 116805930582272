const CustomerGrade = {
    GET_GRADE: 'get-grade',
    GET_GRADE_SUCCESS: 'get-grade-success',
    GET_GRADE_ERROR:'get-grade-error',
    CLEAR_GRADE: 'clear-grade',

    GET_GRADE_CONDITION: 'get-grade-condition',
    GET_GRADE_CONDITION_SUCCESS: 'get-grade-condition-success',
    GET_GRADE_CONDITION_ERROR: 'get-grade-condition-error',

    GET_BLACKLIST: 'get-blacklist',

    getCustomerGrade: (IDCNO) => ({ type: CustomerGrade.GET_GRADE, IDCNO }),
    clearCustomerGrade: () => ({ type: CustomerGrade.CLEAR_GRADE }),
    getGradeCondition: () => ({ type: CustomerGrade.GET_GRADE_CONDITION })
};

export default CustomerGrade;