import GradeAction from './actions';

const {
    GET_GRADE,
    GET_GRADE_SUCCESS,
    GET_GRADE_ERROR,
    CLEAR_GRADE,
    GET_GRADE_CONDITION_SUCCESS,
    GET_GRADE_CONDITION,
    GET_GRADE_CONDITION_ERROR,
    GET_BLACKLIST
} = GradeAction;

const initState = {
    loading: false,
    isBlackList: false,
    customerGradeData: undefined,
    blackList: undefined,
    BLLogs: undefined,
    gradeConditionData: undefined
}

export default function gradeReducer(state = initState, action) {
    switch (action.type) {
        case GET_GRADE:
            return { ...state, loading: true };
        case GET_GRADE_SUCCESS:
            return {
                ...state,
                loading: false,
                customerGradeData: action.data,
                isBlackList: false
            };
        case GET_BLACKLIST: 
            return { ...state, loading: false, blackList: action.data.blackList, BLLogs: action.data.BLLogs, isBlackList: true };
        case GET_GRADE_ERROR:
            return { ...state, loading: false }
        case CLEAR_GRADE:
            return { ...initState };
        case GET_GRADE_CONDITION:
            return { ...state, loading: true };
        case GET_GRADE_CONDITION_SUCCESS:
            return { ...state, loading: false, gradeConditionData: action.data };
        case GET_GRADE_CONDITION_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
}