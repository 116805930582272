const dashboardActons = {
    DASHBOARD_LISTDATA_DASHBOARD: 'DASHBOARD_LISTDATA_DASHBOARD',
    DASHBOARD_LISTDATA_DASHBOARD_SUCCESS: 'DASHBOARD_LISTDATA_DASHBOARD_SUCCESS',
    DASHBOARD_ERROR: 'DASHBOARD_ERROR',
    getDashboard: () => ({
      type: dashboardActons.DASHBOARD_LISTDATA_DASHBOARD,
    }),
    getListerror: () => ({
      type: dashboardActons.DASHBOARD_ERROR,
    }),

  };
  export default dashboardActons;
  