import actions from "./actions";

const initState = {
  Loading: false,
  getUsersLoading: false,
  usersCompaniesData: [],
  usersAllCompanyData: []
}; //init state

export default function connectUserToCompanyReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_USER:
      return {
        ...state,
        getUsersLoading: true
      };
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        getUsersLoading: false,
        usersCompaniesData: action.data,
        numOfRows: action.numOfRows
      };
    case actions.GET_USER_ERROR:
      return {
        ...state,
        getUsersLoading: false
      };

    case actions.CONNECT_USER_TO_COMPANY:
      return {
        ...state,
        Loading: true
      };
    case actions.CONNECT_USER_TO_COMPANY_SUCCESS:
      return {
        ...state,
        Loading: false
      };
    case actions.CONNECT_USER_TO_COMPANY_ERROR:
      return {
        ...state,
        Loading: false
      };
    case actions.DETACH_USER_FROM_COMPANY:
      return {
        ...state,
        loading: true
      }
    case actions.DETACH_USER_FROM_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case actions.DETACH_USER_FROM_COMPANY_ERROR:
      return {
        ...state,
        loading: false
      }
      case actions.SEARCH_USER_ALL_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          usersAllCompanyData: action.data
        }
    default:
      // console.log('default')
      return state;
  }
}
