import { serialize } from "../../utils/serialize";
import superFetch from "../../helpers/superFetch";

export const getBlLogsService = async ID => {
         try {
           return await superFetch.get(`/api/blackList/${ID}/history`);
         } catch (err) {
           return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
         }
       };

export const updateNoteService = async props => {
  try {
    return await superFetch.put(`/api/blackList/note`, props);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const undoReduService = async props => {
  try {
    return await superFetch.put(`/api/blackList`, props);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const createService = async props => {
  try {
    return await superFetch.post(`/api/blackList`, props);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const getOptService = async () => {
  try {
    return await superFetch.get(`/api/blackList/options`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const getService = async props => {
  try {
    return await superFetch.get(`/api/blacklist?${serialize(props)}`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};

export const searchBlackListService = async props => {
  try {
    return await superFetch.get(`/api/blacklist/search?${serialize(props)}`);
  } catch (err) {
    return { message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้" };
  }
};
