const guarantorActions = {
    GET_GRT: 'GET_GRT',
    GET_GRT_SUCCESS: 'GET_GRT_SUCCESS',
    GET_GRT_ERROR: 'GET_GRT_ERROR',

    UPDATE_DELIVERY_NO: 'UPDATE_DELIVERY_NO',
    UPDATE_DELIVERY_NO_SUCCESS: 'UPDATE_DELIVERY_NO_SUCCESS',
    UPDATE_DELIVERY_NO_ERROR: 'UPDATE_DELIVERY_NO_ERROR',

    UPDATE_RESPONSE_NO: 'UPDATE_RESPONSE_NO',
    UPDATE_RESPONSE_NO_SUCCESS: 'UPDATE_RESPONSE_NO_SUCCESS',
    UPDATE_RESPONSE_NO_ERROR: 'UPDATE_RESPONSE_NO_ERROR',

    UPDATE_NOTE: 'UPDATE_NOTE',

    PRINT_GRT_DOCS: 'PRINT_GRT_DOCS',


    GRT_CHECKING: 'GRT_CHECKING',
    GRT_CHECKING_SUCCESS: 'GRT_CHECKING_SUCCESS',
    GRT_CHECKING_ERROR: 'GRT_CHECKING_ERROR',

    grtCheck: (props) => ({
        type: guarantorActions.GRT_CHECKING,
        props  
    }),

    getData: (props) => ({
        type: guarantorActions.GET_GRT,
        ...props
    }),

    updateDeliveryNo: (props) => ({
        type: guarantorActions.UPDATE_DELIVERY_NO,
        ...props
    }),

    updateResponseNo: (props) => ({
        type: guarantorActions.UPDATE_RESPONSE_NO,
        ...props
    }),
    
    updateNoteNo: (props) => ({
        type: guarantorActions.UPDATE_NOTE,
        ...props
    }),

    printGrtDocs: (props) => ({
        type: guarantorActions.PRINT_GRT_DOCS,
        ...props
    })
}

export default guarantorActions
