import { Provider } from 'react-redux';
import React from 'react';
import { store, history } from './redux/store';
import PublicRoutes from './router';

class DashApp extends React.Component {
  render() {
    return (
      <Provider store={store}>  
          <PublicRoutes history={history} />
      </Provider>
    );
  }
}

export default DashApp;
