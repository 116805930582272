const actions = {
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",

  CONNECT_USER_TO_COMPANY: "CONNECT_USER_TO_COMPANY",
  CONNECT_USER_TO_COMPANY_SUCCESS: "CONNECT_USER_TO_COMPANY_SUCCESS",
  CONNECT_USER_TO_COMPANY_ERROR: "CONNECT_USER_TO_COMPANY_ERROR",

  DETACH_USER_FROM_COMPANY: "DETACH_USER_FROM_COMPANY",
  DETACH_USER_FROM_COMPANY_SUCCESS: "DETACH_USER_FROM_COMPANY_SUCCESS",
  DETACH_USER_FROM_COMPANY_ERROR: "DETACH_USER_FROM_COMPANY_ERROR",

  SEARCH_USER_ALL_COMPANY: 'SEARCH_ALL_COMPANY-USER',
  SEARCH_USER_ALL_COMPANY_SUCCESS: 'SEARCH_ALL_COMPANY-USER-SUCCESS',
  SEARCH_USER_ALL_COMPANY_ERROR: 'SEARCH_ALL_COMPANY-USER-ERROR',


  getUsersCompanies: props => ({
    type: actions.GET_USER,
    props
  }),

  searchUserAllCompany: keyword => ({
    type: actions.SEARCH_USER_ALL_COMPANY,
    keyword
  }),

  connectUserToCompany: props => ({
    type: actions.CONNECT_USER_TO_COMPANY,
    ...props
  }),
  detachUser: id => ({
    type: actions.DETACH_USER_FROM_COMPANY,
    id
  })
};
export default actions;
