import actions from './actions';

const initState = {
    loading: false,
    permissionList: [],
    success: true,
    message: '',
};

export default (state = initState, action) => {
    switch (action.type) {
        case actions.GET_PERM_REQUEST:
            return { ...initState, loading: true };
        case actions.GET_PREM_SUCCESS:
            return { ...initState, permissionList: action.data, success: true }
        case actions.GET_PERM_ERROR:
            return { ...initState, message: action.message, success: false };
        case actions.UPDATE_PERM_REQUEST:
            return { ...state, loading: true };
        case actions.UPDATE_PERM_SUCCESS:
            return { ...state, loading: false, success: true };
        case actions.UPDATE_PERM_ERROR:
            return { ...initState, message: action.message, success: false };
        default:
            return state;
    }
}