import superFetch from "../../helpers/superFetch";

export const createService = preparedata => {
  return superFetch.post(`/api/companies`, { ...preparedata });
};

export const updateService = (companyId, preparedata) => {
  return superFetch.put(`/api/companies`, { companyId, ...preparedata });
};

export const deleteService = companyId => {
  return superFetch.delete(`/api/companies`, { companyId });
};

export const getService = () => {
  return superFetch.get(`/api/companies`);
};

export const getCompanyOptionsService = () => superFetch.get('/api/companies/options');
