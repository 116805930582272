const notificationActons = {
  NOTIFACATION_REQUEST: 'NOTIFACATION_REQUEST',
  NOTIFACATION_SENDSUCCESS: 'NOTIFACATION_SENDSUCCESS',
  NOTIFY_ERROR: 'NOTIFY_ERROR',
  NOTIFACATION_GETUSER: 'NOTIFACATION_GETUSER',
  RESET_STATE_NOTIFY: 'RESET_STATE_NOTIFY',
  GET_ALL_SETTING_REQUEST: 'GET_ALL_SETTING_REQUEST',
  GET_ALL_SETTING_REQUEST_SUCCESS: 'GET_ALL_SETTING_REQUEST_SUCCESS',
  SETTING_REQUEST: 'SETTING_REQUEST',
  SETTING_REQUEST_SUCCESS: 'SETTING_REQUEST_SUCCESS',
  GET_NOTIFY_LIST_REQUEST: 'GET_NOTIFY_LIST_REQUEST',
  GET_NOTIFY_LIST_REQUEST_SUCCESS: 'GET_NOTIFY_LIST_REQUEST_SUCCESS',
  FILTER_NAME: 'FILTER_NAME',
  SET_NUM_NOTIFY: 'SET_NUM_NOTIFY',

  getNotifyList: (props) => ({
    type: notificationActons.GET_NOTIFY_LIST_REQUEST,
    ...props
  }),

  filterName: (newDataFilter) => ({
    type: notificationActons.FILTER_NAME,
    newDataFilter
  }),

  settingNotify: (msgID, title, desc) => ({
    type: notificationActons.SETTING_REQUEST,
    msgID,
    title,
    desc
  }),
  getAllSettings: () => ({
    type: notificationActons.GET_ALL_SETTING_REQUEST,
  }),
  postNotifacation: (userID, notifyType, title, description) => ({
    type: notificationActons.NOTIFACATION_REQUEST,
    userID,
    notifyType,
    title,
    description
  }),
  notifacationSuccess: () => ({
    type: notificationActons.NOTIFACATION_SENDSUCCESS,
  }),
  reSetState: () => ({
    type: notificationActons.RESET_STATE_NOTIFY,
  }),
  getUsers: () => ({
    type: notificationActons.NOTIFACATION_GETUSER,
  }),
};
export default notificationActons;
