import { all, takeEvery, put, call, race, fork } from "redux-saga/effects";
import { message } from "antd";
import { delay } from "redux-saga";
import actions from "./actions";
import {
  getSysInfo,
  updateService,
  updateNotifyForm,
  getNotifyForm,
  getBankAccount,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount
} from "./services";

const {
  GET_SYS_INFO,
  GET_SYS_INFO_SUCCESS,
  GET_SYS_INFO_ERROR,
  UPDATE_SYS_INFO,
  GET_NOTIFY_FORM,
  GET_NOTIFY_FORM_SUCCESS,
  GET_NOTIFY_FORM_ERROR,
  UPDATE_NOTIFY_FORM,

  GET_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_ERROR,
  GET_BANK_ACCOUNT_SUCCESS,

  CREATE_BANK_ACCOUNT,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_ERROR,

  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_ERROR,

  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_ERROR
} = actions;

export function* getBankAccountWorker() {
  yield takeEvery(GET_BANK_ACCOUNT, function*({ companyId }) {
    try {
      const { res, timeout } = yield race({
        res: call(getBankAccount, companyId),
        timeout: call(delay, 15000)
      });
      const { success, data } = res;
      if (success && !timeout) {
        yield put({ type: GET_BANK_ACCOUNT_SUCCESS, data });
      } else {
        // message.error(res.message);
        yield put({ type: GET_BANK_ACCOUNT_SUCCESS, data });
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_BANK_ACCOUNT_ERROR });
    }
  });
}

export function* createBankAccountWorker() {
  yield takeEvery(CREATE_BANK_ACCOUNT, function*({ bank_account_info, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(createBankAccount, bank_account_info),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: CREATE_BANK_ACCOUNT_SUCCESS });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: CREATE_BANK_ACCOUNT_ERROR });
    }
  });
}

export function* updateBankAccountWorker() {
  yield takeEvery(UPDATE_BANK_ACCOUNT, function*({ bank_account_info, onSuccess }) {
    console.log("bank_account_info: ", bank_account_info);
    try {
      const { res, timeout } = yield race({
        res: call(updateBankAccount, bank_account_info),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: UPDATE_BANK_ACCOUNT_SUCCESS });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: UPDATE_BANK_ACCOUNT_ERROR });
    }
  });
}

export function* deleteBankAccountWorker() {
  yield takeEvery(DELETE_BANK_ACCOUNT, function*({ accountId, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(deleteBankAccount, accountId),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success(res.message);
        onSuccess();
        yield put({ type: DELETE_BANK_ACCOUNT_SUCCESS });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: DELETE_BANK_ACCOUNT_ERROR });
    }
  });
}

export function* getNotifyFormWorker() {
  yield takeEvery(GET_NOTIFY_FORM, function*({ props }) {
    try {
      const { res, timeout } = yield race({
        res: call(getNotifyForm, props),
        timeout: call(delay, 15000)
      });
      const { success, data, variableDefinition } = res;
      if (success && !timeout) {
        let mergeData;

        if (props.docType === "GUARANTOR") {
          mergeData = {
            grtFormData: data,
            variableDefinitionGrt: variableDefinition
          };
        }
        if (props.docType === "DEBTOR") {
          mergeData = {
            debFormData: data,
            variableDefinitionDeb: variableDefinition
          };
        }
        yield put({
          type: GET_NOTIFY_FORM_SUCCESS,
          ...mergeData
        });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_NOTIFY_FORM_ERROR });
    }
  });
}

export function* updateNotifyFormWorker() {
  yield takeEvery(UPDATE_NOTIFY_FORM, function*({ props, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(updateNotifyForm, props),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success("Update เรียบร้อย");
        onSuccess();

        if (props.docType === "GUARANTOR") {
          yield put({ type: GET_NOTIFY_FORM, props: { docType: "GUARANTOR" } });
        }
        if (props.docType === "DEBTOR") {
          yield put({ type: GET_NOTIFY_FORM, props: { docType: "DEBTOR" } });
        }
        // console.log('getWorker data ', data)
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
    }
  });
}

export function* getWorker() {
  yield takeEvery(GET_SYS_INFO, function*() {
    try {
      const { res, timeout } = yield race({
        res: call(getSysInfo),
        timeout: call(delay, 15000)
      });
      const { success, data } = res;
      if (success && !timeout) {
        yield put({ type: GET_SYS_INFO_SUCCESS, data });
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      yield put({ type: GET_SYS_INFO_ERROR });
    }
  });
}

export function* updateNoteWorker() {
  yield takeEvery(UPDATE_SYS_INFO, function*({ props, onSuccess }) {
    try {
      const { res, timeout } = yield race({
        res: call(updateService, props),
        timeout: call(delay, 15000)
      });
      const { success } = res;
      if (success && !timeout) {
        message.success("Update เรียบร้อย");
        onSuccess();
        yield put({ type: GET_SYS_INFO });
        // console.log('getWorker data ', data)
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error("ไม่สามารถทำรายการได้");
      // yield put({ type: UPDATE_DELIVERY_NO_ERROR });
    }
  });
}

export default function* rootSagas() {
  yield all([
    fork(getWorker),
    fork(updateNoteWorker),
    fork(getNotifyFormWorker),
    fork(updateNotifyFormWorker),
    fork(getBankAccountWorker),
    fork(createBankAccountWorker),
    fork(updateBankAccountWorker),
    fork(deleteBankAccountWorker)
  ]);
}
