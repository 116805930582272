
import actions from './actions';

const {
    CHECK_CONTRACT_AGE,
    CHECK_CONTRACT_AGE_SUCCESS,
    CHECK_CONTRACT_AGE_ERROR,
} = actions

const initState = {
    getLoading: false,
    contractAgeList: [],
    numOfData: 0
};

export default function checkContractAgeReducer(state = initState, action) {
    switch (action.type) {
        case CHECK_CONTRACT_AGE:
            return {
                ...state,
                getLoading: true
            };
        case CHECK_CONTRACT_AGE_SUCCESS:
            return {
                ...state,
                getLoading: false,
                contractAgeList: action.data,
                numOfData: action.numOfData
            };
        case CHECK_CONTRACT_AGE_ERROR:
            return {
                ...state,
                getLoading: false
            };

        default:
            return state;
    }
}
