import actions from './actions';

const initState = {
    policyActive: [],
    policyInactive: ['init'],
    Loading: false,
    numOfPolicyActive: 0,
    numOfPolicyInActive: 0
}; //init state

export default function statementReducer(state = initState, action) {
  switch (action.type) {
    case actions.STATEMENT_POLICYACTIVE:
      return {
        ...state,
        Loading: true,
      };
    case actions.STATEMENT_POLICYACTIVE_SUCCESS:
      return {
        ...state,
        Loading: false,
        policyActive: action.payload,
        numOfPolicyActive: action.numOfPolicyActive
      };
    case actions.STATEMENT_POLICYINACTIVE:
      return {
        ...state,
        Loading: true,
      };
    case actions.STATEMENT_POLICYINACTIVE_SUCCESS:
      return {
        ...state,
        Loading: false,
        policyInactive: action.payload,
        numOfPolicyInActive: action.numOfPolicyInActive
      };
    case actions.STATEMENT_ERROR:
      return {
        ...state,
        ...initState,
        success: action.success,
        message: action.message
      };
    default:
      // console.log('default')
      return state;
  }
}
